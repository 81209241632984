import HorizontalBlank from '@pages/components/common/atoms/HorizontalBlank';
import styled from 'styled-components';
import CheckTerms from './templates/CheckTerms';

const TermsPage = () => {
  return (
    <Container>
      <TitleFont>개인정보 수집 및 이용에 대한 동의</TitleFont>
      <HorizontalBlank height={10} />
      <GrayDescriptionFont>
        주식회사 디에이치엘코리아는 ePass 서비스(https://kr-epassport.dhl.com/passport)를 통한 서비스 제공을 위하여 아래와 같이
        귀하의 개인정보를 처리합니다. <br />
        이하의 내용을 잘 살펴보시고, 회사의 운송약관과 개인정보 수집 및 이용에 대해 동의해주시기 바랍니다.
      </GrayDescriptionFont>
      <HorizontalBlank height={60} />
      <CheckTerms />
    </Container>
  );
};

const Container = styled.div`
  padding: 15px 30px;
  @media only screen and (max-width: 767px) {
    padding: 0 15px;
  }
`;

const TitleFont = styled.div`
  color: rgb(60, 72, 88);
  font-weight: 600;
  font-size: 36px;
  @media only screen and (max-width: 767px) {
    font-size: 21px;
  }
  text-align: center;
`;

const GrayDescriptionFont = styled.div`
  color: rgb(60, 72, 88);
  font-weight: 500;
  font-size: 17px;
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
  text-align: center;
`;

export default TermsPage;

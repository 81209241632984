import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { updatePassword } from '@apis/password';
import { Button, Form, Input, message, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import { FC, useRef, useState } from 'react';
import styled from 'styled-components';

interface PasswordChangeModalInterface {
  visible: boolean;
  onClose: () => void;
}
const PasswordChangeModal: FC<PasswordChangeModalInterface> = ({ visible, onClose }) => {
  const [originPassword, setOriginPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const formRef = useRef<any>(null);

  const [form] = useForm();

  const handleUpdate = async (submitData: any) => {
    try {
      await updatePassword({ password: newPassword, orgPassword: originPassword });
      message.success('비밀번호를 변경하였습니다.');
      onClose();
    } catch (e: any) {
      const status = e?.response?.status;

      if (status === 401) {
        const errorMessage = e?.response?.data?.message;
        message.error(errorMessage || '비밀번호 변경에 실패하였습니다.');
      } else {
        message.error(e?.response?.statusText || '비밀번호 변경에 실패하였습니다.');
      }
    }
  };

  const onReset = () => {
    form.resetFields();
  };
  return (
    <Modal
      title={
        <Space>
          <ExclamationCircleTwoTone twoToneColor={'#D40511'} />
          패스워드 변경
        </Space>
      }
      visible={visible}
      okText={'변경'}
      cancelText={'다시입력'}
      onCancel={onClose}
      footer={[]}>
      <Form
        form={form}
        onFinish={handleUpdate}
        ref={formRef}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}>
        <Form.Item
          label='현재 비밀번호'
          rules={[
            {
              required: true,
              message: '현재 비밀번호를 입력해주세요',
            },
          ]}
          name='originPassword'>
          <Input.Password value={originPassword} onChange={({ target }) => setOriginPassword(target.value)} />
        </Form.Item>
        <Form.Item
          label='새 비밀번호'
          rules={[
            {
              required: true,
              message: '새 비밀번호를 입력해주세요',
            },
            {
              validator: (_, value) => {
                const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/;

                if (reg.test(value) || !value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error('비밀번호는 최소 10자이상, 문자와 숫자와 특수문자를 최소 1자 이상 입력해주세요.'),
                  );
                }
              },
            },
          ]}
          name='newPassword'>
          <Input.Password value={newPassword} onChange={({ target }) => setNewPassword(target.value)} />
        </Form.Item>
        <Form.Item
          label='새 비밀번호 확인'
          rules={[
            {
              required: true,
              message: '새 비밀번호를 다시 입력해주세요',
            },
            {
              validator: (_, value) => {
                if (newPassword !== newPassword2) {
                  return Promise.reject(new Error('비밀번호를 일치시켜주세요.'));
                }
                const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/;

                if (reg.test(value) || !value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error('비밀번호는 최소 10자이상, 문자와 숫자와 특수문자를 최소 1자 이상 입력해주세요.'),
                  );
                }
              },
            },
          ]}
          name='newPassword2'>
          <Input.Password value={newPassword2} onChange={({ target }) => setNewPassword2(target.value)} />
        </Form.Item>
        <ButtonArea>
          <Space>
            <Button type={'primary'} htmlType='submit'>
              변경
            </Button>
            <Button onClick={onReset}>다시입력</Button>
          </Space>
        </ButtonArea>
      </Form>
    </Modal>
  );
};

const ButtonArea = styled(Form.Item)`
  justify-content: flex-end;
`;

export default PasswordChangeModal;

import { ExclamationCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import CommonContainer from '@pages/components/common/atoms/CommonContainer';
import { Space } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import PaymentList from './templates/PaymentList';
import SearchForm, { SearchDataInterface } from './templates/SearchForm';
const HomePage = () => {
  const [param, setParam] = useState<SearchDataInterface>();
  const [searched, isSearched] = useState<boolean>();
  return (
    <CommonContainer>
      <Description>
        <ExclamationCircleTwoTone twoToneColor={'#D40511'} />
        Dhl 온라인 카드결제/무통장 입금을 신청한 민원인 정보만 조회가 가능합니다.
      </Description>
      <Description>
        <ExclamationCircleTwoTone twoToneColor={'#D40511'} />
        <SearchOutlined />를 클릭하시면 발송물 배달조회가 가능합니다.
      </Description>

      <SearchForm
        onSearch={(searchData) => {
          setParam({ ...searchData });
          isSearched(true);
        }}
      />
      {searched && <PaymentList {...param} />}
    </CommonContainer>
  );
};

const Description = styled(Space)`
  align-items: center;
  height: 40px;
`;
export default HomePage;

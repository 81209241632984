import { Modal } from 'antd';
import { FC, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import Footer from './Footer';

interface ConfirmModalInterface {
  visible: boolean;
  onClose: () => void;
  param?: ObjectInterface | null;
}

const Title = () => <TitleContainer>결제하기(신용카드)</TitleContainer>;

const ConfirmModal: FC<ConfirmModalInterface> = ({ visible, onClose, param }) => {
  
  const relevantNameList = useMemo(() => {
    if (param) {
      const keys = Object.keys(param).filter((item) => item.startsWith('relevantName'));
      return keys.map((key) => param?.[key]);
    }
  }, [param]);

  const relevantIdNumList = useMemo(() => {
    if (param) {
      const keys = Object.keys(param).filter((item) => item.startsWith('relevantIdNum'));
      return keys.map((key) => param?.[key]);
    }
  }, [param]);

  const insurcYn = useMemo(() => {
    
    if (param?.insurcYn == true) {
      param.insurcYn = 'Y';
      return param.insurcYn;
    } else if (param?.insurcYn == false) {
      param.insurcYn = 'N';
      return param.insurcYn;
    }
  }, [param]);

  const gogreenYn = useMemo(() => {
    
    if (param?.gogreenYn == true) {
      param.gogreenYn = 'Y';
      return param.gogreenYn;
    } else if (param?.gogreenYn == false) {
      param.gogreenYn = 'N';
      return param.gogreenYn;
    }
  }, [param]);

  const bnameList = relevantNameList
    ?.map((item, i) => {
      return `${item}(${relevantIdNumList?.[i]})`;
    })
    .join(',\r\n');

  const orderId = useMemo(() => {
    const chars = '0123456789ABCDEFGHJKLMNPQRTUVWXTZ';
    const string_length = 4;
    let orderId = '';
    let randomstring = '';
    const mdnum = (crypto.getRandomValues(new Uint8Array(1))[0] % 8) + 1;

    const randomValues = crypto.getRandomValues(new Uint8Array(string_length));
    for (let i = 0; i < string_length; i++) {
      const rnum = randomValues[i] % chars.length;
      randomstring += chars.charAt(rnum);
    }
    orderId = mdnum + randomstring + 'S';
    
    return orderId;
  }, []);
  //<TableRow label={'주문 번호'} content={param?.embassyCode + orderId} />
  return (
    <Modal
      destroyOnClose={true}
      footer={<Footer onClose={onClose} param={param} orderId={param?.embassyCode + orderId} bnameList={bnameList} />}
      visible={visible}
      title={<Title />}>
      <div className='ant-descriptions ant-descriptions-bordered'>
        <div className='ant-descriptions-view'>
          <table>
            <tbody>
              <TableRow label={'DHL 서비스명'} content={param?.serviceName} />
              <TableRow label={'거주 국가'} content={param?.ctryName} />
              <TableRow label={'공관명'} content={param?.embassyName} />
              <TableRow
                label={'결제 금액'}
                content={<NumberFormat value={param?.totalAmount} displayType={'text'} thousandSeparator={true} suffix={'원'} />}
              />
              <TableRow label={'민원인 정보'} />
              <TableRow label={'신청인'} content={param?.buyerName} />
              <TableRow label={'여권발급 대상자'} content={bnameList} />
              <TableRow label={'전화'} content={param?.phoneNumber} />
              <TableRow label={'이메일'} content={param?.email} />
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

interface TableRowInterface {
  label: string;
  content?: React.ReactNode;
}

const TableRow: FC<TableRowInterface> = ({ label, content }) => {
  return (
    <tr className='ant-descriptions-row'>
      <th
        className='ant-descriptions-item-label'
        colSpan={content ? 1 : 2}
        style={{ paddingTop: 8, paddingBottom: 8, whiteSpace: 'pre', fontSize: 12 }}>
        <span>{label}</span>
      </th>
      {content && (
        <td
          className='ant-descriptions-item-content'
          style={{ paddingTop: 8, paddingBottom: 8, whiteSpace: 'pre', fontSize: 12 }}>
          <span>{content}</span>
        </td>
      )}
    </tr>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d40511;
  /* background-color: rgb(255, 204, 0); */
`;

export default ConfirmModal;
import HomePage from '@pages/home';
import LoginPage from '@pages/login';
import PassportMain from '@pages/passport/main';
import PaymentPage from '@pages/passport/payment';
import SearchPage from '@pages/passport/search';
import TermsPage from '@pages/passport/terms';
import { Navigate, useRoutes } from 'react-router-dom';

export enum EnumRouteUrl {
  HOME = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  CUSTOMER_LIST = '/customerList',
  CUSTOMER_DETAIL = '/customerDetail/:accntNo',
  PASSPORT_MAIN = '/passport',
  TERMS = '/passportTerms',
  PAYMENT = '/passportPayment',
  RESULT = '/passportResult',
  SEARCH = '/passportSearch',
}

export interface RouteInterface {
  path: EnumRouteUrl;
  exact: boolean;
  component: any;
}

export const LoginRoutes = () => {
  let routes = useRoutes([
    { path: EnumRouteUrl.LOGIN, element: <LoginPage /> },
    { path: '*', element: <Navigate replace to={EnumRouteUrl.LOGIN} /> },
  ]);
  return routes;
};

export const PassportRoutes = () => {
  return useRoutes([
    {
      path: EnumRouteUrl.PASSPORT_MAIN,
      element: <PassportMain />,
    },
    {
      path: EnumRouteUrl.TERMS,
      element: <TermsPage />,
    },
    {
      path: EnumRouteUrl.PAYMENT,
      element: <PaymentPage />,
    },
    {
      path: EnumRouteUrl.SEARCH,
      element: <SearchPage />,
    },
    { path: '*', element: <Navigate replace to={EnumRouteUrl.PASSPORT_MAIN} /> },
  ]);
};

export const HomeRoutes = () => {
  return useRoutes([
    { path: EnumRouteUrl.HOME, element: <HomePage /> },
    { path: '*', element: <Navigate replace to={EnumRouteUrl.HOME} /> },
  ]);
};

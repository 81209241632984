import { SearchOutlined } from '@ant-design/icons';
import { postHawb } from '@apis/hawb';
import { Space } from 'antd';
import useModal from 'antd/lib/modal/useModal';
import { FC, useState } from 'react';
import { PaymentListItemInterface } from './PaymentList';

interface UpdateHawbObInterface {
  data: PaymentListItemInterface;
  mutate: () => void;
}

const UpdateHawbOb: FC<UpdateHawbObInterface> = ({ data, mutate }) => {
  const [modal, contextHolder] = useModal();
  const { ptype, invNoOb, pgCancelIdx, hawbOb } = data;
  const [newHawbOb, setNewHawbOb] = useState<string>(data.hawbOb);
  //   const [trackVisible, isTrackVisible] = useState(false);

  //   const searchHandle = () => {
  //     isTrackVisible(true);
  //   };

  const hawbCheck = (hawbTmp: string) => {
    let tmp: string, a: string, b: string, c: string;
    tmp = hawbTmp;
    a = tmp.substr(0, 9);
    b = tmp.substr(9, 1);
    c = (parseInt(a) % 7).toString();

    if (b !== c) {
      return false;
    } else {
      return true;
    }
  };
  const getConfig = () => {
    if (newHawbOb) {
      if (hawbOb === newHawbOb) {
        return ['기존 운송장 번호와 동일합니다.', false];
      } else if (newHawbOb.length !== 10) {
        return ['DHL 운송장번호는 10자리 숫자입니다.', false];
      } else if (!hawbCheck(newHawbOb)) {
        return ['DHL 운송장번호가 아닙니다. 다시 한 확인하세요.', false];
      } else {
        return [`운송장번호를 ${newHawbOb}로 업데이트 하시겠습니까?`, true];
      }
    } else {
      if (hawbOb) {
        return ['운송장번호를 입력하지 않으셨습니다. 공백으로 업데이트 하시겠습니까?', true];
      } else {
        return ['운송장번호를 입력하세요.', false];
      }
    }
  };
  const updateHandle = () => {
    const [message, isUpdate] = getConfig();

    if (isUpdate) {
      modal.confirm({
        onOk: async () => {
          await postHawb({ ...data, hawbOld: data.hawbOb, hawbNew: newHawbOb || ' ' });
          mutate();
        },
        title: '환불 대상자 등록',
        content: message,
      });
    } else {
      modal.error({
        title: '환불 대상자 등록',
        content: message,
      });
    }
  };

  const render = () => {
    if (ptype === 'O') {
      if (hawbOb) {
        return (
          <Space>
            <div>{hawbOb}</div>
            <SearchOutlined
              onClick={() =>
                window.open(
                  `https://www.dhl.com/cgi-bin/tracking.pl?AWB=${hawbOb}&LINKS=NO&LAN=KOR&TID=KR_KOR`,
                  '운송장번호 조회',
                  'width=1420, toolbar=no, menubar=no',
                )
              }
            />
            {/* <EditOutlined onClick={() => updateHandle()} /> */}
          </Space>
        );
      } else if (!invNoOb && pgCancelIdx) {
        return '취소';
      } else if (!invNoOb && !pgCancelIdx) {
        return (
          <Space>
            <div>{hawbOb}</div>
            {/* <EditOutlined onClick={() => updateHandle()} /> */}
          </Space>
        );
      } else if (invNoOb) {
        return hawbOb;
      }
    } else {
      return 'ERROR';
    }
  };
  return (
    <>
      {render()}
      {contextHolder}
    </>
  );
};

export default UpdateHawbOb;

import { Method } from 'axios';

export interface ApiInterface {
  endpoint: string;
  method: Method;
}

// interface ApiObjectInterface {
//   [key: keyof API]: ApiInterface;
// }

export const API = {
  LOGIN: {
    endpoint: '/comm/control/auth',
    method: 'POST',
  } as ApiInterface,
  EMBASSY_CTRY_SELECT: {
    endpoint: '/dhl/kr/epass/embassy/searchEmbassyCtry',
    method: 'GET',
  } as ApiInterface,
  EMBASSY_SELECT: {
    endpoint: '/dhl/kr/epass/embassy/searchEmbassy',
    method: 'GET',
  } as ApiInterface,
  CUSTOMER_CTRY_SELECT: {
    endpoint: '/dhl/kr/epass/customer/searchEmbassyCtry',
    method: 'GET',
  } as ApiInterface,
  CUSTOMER_SELECT: {
    endpoint: '/dhl/kr/epass/customer/searchEmbassyList',
    method: 'GET',
  } as ApiInterface,
  CUSTOMER_AMOUNT: {
    endpoint: '/dhl/kr/epass/customer/searchEmbassyAmount',
    method: 'GET',
  } as ApiInterface,
  PAYMENT_LIST: {
    endpoint: 'dhl/kr/epass/embassy/searchPaymentResult',
    method: 'GET',
  } as ApiInterface,
  RECEIPE_DETAIL: {
    endpoint: 'dhl/kr/epass/embassy/searchPaymentReceipe',
    method: 'GET',
  } as ApiInterface,
  UPDATE_PAYMENT_REJECT: {
    endpoint: 'dhl/kr/epass/embassy/updatePaymentReject',
    method: 'POST',
  } as ApiInterface,
  UPDATE_HAWB: {
    endpoint: 'dhl/kr/epass/embassy/updateHawb',
    method: 'POST',
  },
  UPDATE_PASSWORD: {
    endpoint: 'dhl/kr/epass/embassy/updatePwd',
    method: 'POST',
  } as ApiInterface,
  PAYMENT_RESULT: {
    endpoint: '/dhl/epass/pp_cli_hub',
    method: 'POST',
  } as ApiInterface,
  SIGNIN: {
    endpoint: '/comm/control/auth/signupUser',
    method: 'POST',
  } as ApiInterface,
  SIGNIN_CERTI: {
    endpoint: '/comm/control/auth/signupUserCerti',
    method: 'POST',
  } as ApiInterface,
  SIGNIN_CERTI_EMAIL: {
    endpoint: '/comm/control/auth/signupUserCertiEmail',
    method: 'POST',
  } as ApiInterface,
  LOGIN_CERTI: {
    endpoint: '/comm/control/auth/loginUserCerti',
    method: 'POST',
  } as ApiInterface,
  LOGIN_USER_UPDATE_EMAIL_AGREE: {
    endpoint: '/comm/control/auth/loginUserUpdateEmail',
    method: 'POST',
  } as ApiInterface,
  LOGIN_CERTI_EMAIL: {
    endpoint: '/comm/control/auth/loginUserCertiEmail',
    method: 'POST',
  } as ApiInterface,
  SIGNIN_CERTI_CONFIRM: {
    endpoint: '/comm/control/auth/signupUserCertiConfirm',
    method: 'POST',
  } as ApiInterface,
  LOGIN_CERTI_CONFIRM: {
    endpoint: '/comm/control/auth/logininUserCertiConfirm ',
    method: 'POST',
  } as ApiInterface,
  PASSPORT_PAYMENT_SEARCH: {
    endpoint: '/dhl/kr/epass/customer/searchPayment',
    method: 'get',
  } as ApiInterface,
};

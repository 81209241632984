import CARD from '@assets/images/card.png';
import CHECKMARK from '@assets/images/checkmark.png';
import { EnumRouteUrl } from '@constants/ConstRoute';
import HorizontalBlank from '@pages/components/common/atoms/HorizontalBlank';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CardTemplate from './tempates/CardTemplate';

const PassportMain = () => {
  const navigate = useNavigate();

  //DA설정 스크립트
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://vawidget.dhl.com/latest/embed.js';
    script.id = 'dhl-va-widget';
    document.body.appendChild(script);
   
    script.onload = () => {
      const dhlVAWidget = (window as any).dhlVAWidget;
      if (typeof dhlVAWidget !== 'undefined') {
        dhlVAWidget.init({
          id: 'express-cs-ap-2/discover/kr/c0b27d5b-57e7-4238-9cd5-0c3d9f6bd17d',
          languageCode: 'ko',
          countryCode: 'KR',
        });
      }
    };

    return () => {
      // 컴포넌트가 언마운트될 때 스크립트 요소를 제거합니다.
      document.body.removeChild(script); 
    };
  }, []);

  // 쿠키관련 스크립트
  useEffect(() => {
     const script1 = document.createElement('script');
     script1.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
     script1.type = 'text/javascript';
     script1.charset = 'UTF-8';
     script1.dataset.documentLanguage = 'true';
     script1.dataset.domainScript = 'ece0d8f8-48f3-4722-9144-7d5d102c64d3';
 
     // OptanonWrapper 함수를 추가하는 스크립트 요소를 생성합니다.
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.innerHTML = 'function OptanonWrapper() { }';
    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      // 컴포넌트가 언마운트될 때 스크립트 요소를 제거합니다.
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);


  return (
    <Container>
      <RedTitleFont>
        <i>DHL Express </i>
        긴급여권특급 배송서비스
      </RedTitleFont>
      <HorizontalBlank height={15} />
      <GrayDescriptionFont>
        해외에서 긴급으로 여권 발급이 필요한 대한민국 국민을 위해 외교통상부는 국제특송업체 DHL과 함께 '
        <b>긴급여권특급 배송서비스</b>
        '를 제공하고 있습니다.
        <br />
        여권 평균 배송 소요 시간은 동북아 및 미주는 1~2일, 유럽2~3일, 중동 및 남미 지역은 3~4일이 소요됩니다.(영업일기준) <br />
        여권은 해외영사관으로 배송됩니다.
      </GrayDescriptionFont>
      <HorizontalBlank height={40} />
      <CardContainer>
        <CardTemplate
          buttonName='결제'
          onButtonClick={() => {
            navigate(EnumRouteUrl.TERMS);
          }}
          icon={<Icon src={CARD} />}
          text1='결제하기(신용카드)'
          text2="DHL 전자결제 시스템을 통한 '긴급여권 배송 서비스' 신용카드 결제 메뉴"
        />
        <CardTemplate
          icon={<Icon src={CHECKMARK} />}
          buttonName='조회'
          onButtonClick={() => {
            navigate(EnumRouteUrl.SEARCH);
          }}
          text1='긴급여권 배송 및 결제내역'
          text2="결제 후 DHL 예약번호를 통하여 'DHL 운송장번호'와 결제내역을 조회하는 메뉴"
        />
        {/* <CardTemplate
          icon={<Icon src={INFORMATION} />}
          onButtonClick={() => {}}
          buttonName='다운로드'
          text1='설명보기(PDF)'
          text2='DHL 긴급여권 특급 배송 서비스 이용안내관련 PDF 파일 다운로드 메뉴'
        /> */}
      </CardContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 15px 30px;
  @media only screen and (max-width: 767px) {
    padding: 0px 10px;
  }
`;

const RedTitleFont = styled.div`
  color: #d40511;
  text-shadow: 2px 2px 1px #e0e0e0;
  font-weight: 900;
  font-size: 40px;
  @media only screen and (max-width: 767px) {
    font-size: 26px;
  }
  text-align: center;
`;

const GrayDescriptionFont = styled.div`
  color: rgb(60, 72, 88);
  font-weight: 300;
  font-size: 22px;
  @media only screen and (max-width: 767px) {
    font-size: 15px;
  }
  text-align: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
`;
export default PassportMain;

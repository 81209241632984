import { GlobalFormat } from '@constants/Format';
import { getStorageItem, STORAGE_KEY } from '@utils/storage';
import { Button, Checkbox, DatePicker, Form, Input, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import SelectCountry from './SelectCountry';

interface SearchFormInterface {
  onSearch: (data: SearchDataInterface) => void;
}

export interface SearchDataInterface {
  ctry?: string;
  embassyCode?: string;
  stat?: string;
  bnameList?: string;
  paydateFrom?: string;
  paydateTo?: string;
  bname?: string;
  reference?: string;
  payType?: string;
  rejectYn?: string;
}

interface UserInfo {
  appRole: string;
  ctry?: string; // 필요한 속성 추가
  embassyCode?: string;
}

const SearchForm: FC<SearchFormInterface> = ({ onSearch }) => {
  const userInfo: UserInfo = getStorageItem(STORAGE_KEY.USER_INFO);
  const [form] = useForm();
  const [requiredDate, isRequiredDate] = useState(true);

  const onReset = () => {
    form.resetFields();
  };

  const initialCountry = useMemo(() => {
    if (!userInfo) {
      return { ctry: '', embassyCode: '' }; // 기본값으로 빈 문자열 설정
    }
    const { appRole, ctry, embassyCode } = userInfo;
    return appRole === 'E' ? { ctry, embassyCode } : { ctry: '', embassyCode: '' }; // appRole이 'E'인 경우만 ctry를 설정
  }, [userInfo]);

  return (
    <SForm
      initialValues={{
        ...initialCountry,
        paydate: [moment().add(-30, 'day'), moment()],
        stat: '',
      }}
      form={form}
      layout='inline'
      className='components-table-demo-control-bar'
      style={{ marginBottom: 16 }}
      onValuesChange={(data) => {
        if (data?.ctry) {
          form.resetFields(['embassyCode']);
        }

        if (data?.rejectYn?.length) {
          form.setFieldsValue({
            rejectYn: 'Y',
          });
        }

        if (data?.stat) {
          isRequiredDate(data?.stat !== 'N');
        }
      }}
      onFinish={(submitData: any) => {
        if (submitData?.paydate) {
          const [from, to] = submitData.paydate;
          submitData = {
            ...submitData,
            paydateFrom: from.format(GlobalFormat.DateDash),
            paydateTo: to.format(GlobalFormat.DateDash),
          };
          delete submitData.paydate;
        }
        onSearch(submitData);
      }}>
      <ItemContainer>
        <Label>{'국가 및 공관'}</Label>
        <Content>
          <Item>
            <SelectCountry initCtryCode={initialCountry.ctry} initEmbassyCode={initialCountry.embassyCode} />
          </Item>
        </Content>
      </ItemContainer>
      <ItemContainer>
        <Label>{'처리상태'}</Label>
        <Content>
          <Item name={'stat'}>
            <Select
              placeholder={'처리상태'}
              options={[
                {
                  label: '전체',
                  value: '',
                },
                {
                  label: '완료건 (DHL운송장번호 입력)',
                  value: 'Y',
                },
                {
                  label: '미결건 (DHL운송장번호 미입력)',
                  value: 'N',
                },
              ]}
            />
          </Item>
        </Content>
      </ItemContainer>
      <ItemContainer>
        <Label>{'여권발급대상자'}</Label>
        <Content>
          <Item name={'bnameList'}>
            <Input />
          </Item>
        </Content>
      </ItemContainer>
      <ItemContainer>
        <Label>{'DHL 결제일'}</Label>
        <Content>
          <Item
            name={'paydate'}
            rules={[
              {
                required: requiredDate,
                message: 'DHL 결제일을 입력해주세요.',
              },
            ]}>
            <DatePicker.RangePicker format={GlobalFormat.DateDash} picker={'date'} />
          </Item>
        </Content>
      </ItemContainer>
      <ItemContainer>
        <Label>{'신청인'}</Label>
        <Content>
          <Item name={'bname'}>
            <Input placeholder={'신청인'} />
          </Item>
        </Content>
      </ItemContainer>
      <ItemContainer>
        <Label>{'예약번호'}</Label>
        <Content>
          <Item name={'reference'}>
            <Input />
          </Item>
        </Content>
      </ItemContainer>
      <ItemContainer>
        <Item name={'rejectYn'}>
          <Checkbox.Group>
            <Checkbox value={'Y'}>환불대상자 업데이트 내역만 조회</Checkbox>
          </Checkbox.Group>
        </Item>
      </ItemContainer>
      <ButtonArea>
        <ButtonSpace>
          <Button type={'primary'} htmlType='submit'>
            조회
          </Button>
          <Button onClick={onReset}>초기화</Button>
        </ButtonSpace>
      </ButtonArea>
    </SForm>
  );
};

const SForm = styled(Form)`
  background-color: #eee;
  padding: 12px;
`;

const Item = styled(Form.Item)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
`;
const Label = styled.div`
  width: 110px;
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
`;

const Content = styled.div`
  width: 220px;
  display: flex;
`;

const ItemContainer = styled(Space)`
  margin: 10px;
  flex-direction: row;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;
const ButtonArea = styled.div`
  flex: 1;
  padding: 8px 15px;
`;

const ButtonSpace = styled(Space)`
  float: right;
`;

export default SearchForm;

import { EnumRouteUrl } from '@constants/ConstRoute';
import { getStorageItem, removeStorageItem, setStorageItem, STORAGE_KEY } from '@utils/storage';
import axios from 'axios';
import { authApi } from './useRequest';

let isTokenRefreshing = false;

export const RefreshTokenAndReattempRequests = async (error: any) => {
  try {
    const { response: errorResponse } = error;

    // 저장된 Refresh Token 소환
    const refreshToken = await getStorageItem(STORAGE_KEY.REFRESH_TOKEN);

    // 저장된 Refresh Token이 없으면 갱신 중단
    if (!refreshToken) {
      throw errorResponse;
    }

    // 토큰 갱신이 진행되는 동안 요청되는 Request들을 대기 리스트에 저장
    const queuedRequest = stackRequestQueue(errorResponse);

    // 토큰 갱신 시작
    if (!isTokenRefreshing) {
      isTokenRefreshing = true;
      // 새 토큰 발급
      //   const newTokens = await getAuthTokensByRefreshToken({
      //     refreshToken: refreshTokenFromStorage,
      //   });
      const res = await authApi.post('/comm/control/auth/refreshToken', {
        refreshToken,
      });

      // 새 토큰 발급 실패 시 갱신 중단
      if (!res.data?.accessToken || !res.data?.refreshToken) {
        throw errorResponse;
      }

      //   // 갱신된 신규 토큰 저장
      //   storeTokens(newTokens);
      setStorageItem(STORAGE_KEY.ACCESS_TOKEN, res.data?.accessToken);
      setStorageItem(STORAGE_KEY.REFRESH_TOKEN, res.data?.refreshToken);

      // 토큰 갱신 종료
      isTokenRefreshing = false;

      // 토큰 갱신이 진행되는 동안 저장했던 Request들을 처리
      consumeRequestQueues(res.data?.accessToken);
    }

    return queuedRequest;
  } catch (err) {
    removeStorageItem(STORAGE_KEY.ACCESS_TOKEN);
    removeStorageItem(STORAGE_KEY.REFRESH_TOKEN);
    removeStorageItem(STORAGE_KEY.USER_INFO);
    window.location.href = EnumRouteUrl.LOGIN;
    return Promise.reject(err);
  }
};

type TRequestQueue = (accessToken: string) => void;
let requestQueues: TRequestQueue[] = [];

export const stackRequestQueue = (errorResponse: any) =>
  new Promise((resolve) => {
    requestQueues.push((accessToken) => {
      errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
      resolve(axios(errorResponse.config));
    });
  });

export const consumeRequestQueues = (accessToken: string) => {
  for (const queue of requestQueues) {
    queue(accessToken);
  }

  requestQueues = [];
};

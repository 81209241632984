import CryptoJS from 'crypto-js';

export enum STORAGE_KEY {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  USER_INFO = 'USER_INFO',
}

const SECRET_KEY = 'your-secret-key';

const encrypt = (value: string) => {
  return CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
};

const decrypt = (encryptedValue: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
  try {
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('Error decrypting data', error);
    return null;
  }
};

export const setStorageItem = (key: STORAGE_KEY, value: any) => {
  try {
    const stringValue = JSON.stringify(value);
    const encryptedValue = encrypt(stringValue);
    localStorage.setItem(key, encryptedValue);
  } catch (error) {
    console.error('Error setting storage item', error);
  }
};

export const getStorageItem = (key: STORAGE_KEY) => {
  try {
    const encryptedValue = localStorage.getItem(key);
    if (encryptedValue) {
      const decryptedValue = decrypt(encryptedValue);
      return decryptedValue ? JSON.parse(decryptedValue) : null;
    }
    return null;
  } catch (error) {
    console.error('Error getting storage item', error);
    return null;
  }
};

export const removeStorageItem = (key: STORAGE_KEY) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error removing storage item', error);
  }
};
import HorizontalBlank from '@pages/components/common/atoms/HorizontalBlank';
import { YellowButton } from '@pages/components/common/atoms/YellowButton';
import { Col, Row, Table } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import DocumentGogeenInfoModal from './DocumentGogeenInfoModal';
import DocumentSafeInfoModal from './DocumentSafeInfoModal';


interface CheckboxItemInterface {
  value: string;
  required: boolean;
  validMessage?: string;
  label: React.ReactNode;
}

export const CheckTerms: FC = () => {
  const [next, isNext] = useState(false);
  const [gogeenModalVisible, setGogeenModalVisible] = useState(false); // State for Gogeen modal
  const [safeModalVisible, setSafeModalVisible] = useState(false); // State for Safe modal
  const [confirmValue, setConfirmValue] = useState(false);
  

   // Function to handle confirmation from Gogeen modal
  const handleGogeenConfirm = (answer: boolean) => {
    setConfirmValue(answer);
    setGogeenModalVisible(false); // Close Gogeen modal
    setSafeModalVisible(true);
  };

  const checkboxDataList: CheckboxItemInterface[] = [
    {
      value: '운송약관',
      required: true,
      validMessage: '회사의 운용약관에 관한 설명을 이해하고, 이에 동의하여 주시기 바랍니다.',
      label: (
        <TermsLabelFont>
          본인은 회사의 <BlueFont onClick={() => window.open('https://mydhl.express.dhl/kr/ko/legal.html')}>운송약관</BlueFont>에
          관한 설명을 이해하고, 이에 동의합니다.
        </TermsLabelFont>
      ),
    },
    {
      value: '개인정보1',
      required: true,
      validMessage: '회사의 일반 개인정보 수집 및 이용에 관한 설명을 이해하고, 이에 동의하여 주시기 바랍니다.',
      label: <TermsLabelFont>(필수) 일반 개인정보의 수집 및 이용에 대해 동의합니다.</TermsLabelFont>,
    },
    {
      value: '개인정보2',
      required: true,
      validMessage: '회사의 일반 개인정보 수집 및 이용에 관한 설명을 이해하고, 이에 동의하여 주시기 바랍니다.',
      label: <TermsLabelFont>(필수) 일반 개인정보의 제3자 제공에 대해 동의합니다.</TermsLabelFont>,
    },
    {
      value: '개인정보3',
      required: true,
      validMessage: '회사의 일반 개인정보 수집 및 이용에 관한 설명을 이해하고, 이에 동의하여 주시기 바랍니다.',
      label: <TermsLabelFont>(필수) 개인정보의 국외이전에 동의합니다.</TermsLabelFont>,
    },
    {
      value: '개인정보4',
      required: true,
      validMessage: '회사의 일반 개인정보 수집 및 이용에 관한 설명을 이해하고, 이에 동의하여 주시기 바랍니다.',
      label: <TermsLabelFont>(필수) 만 14세 이상입니다.</TermsLabelFont>,
    },
    {
      value: '마케팅1',
      required: false,
      label: (
        <TermsLabelFont>
          본인은 하기와 같은 개인정보의 수집 및 이용에 동의합니다. 
        </TermsLabelFont>
      ),
    },
    {
      value: '마케팅2',
      required: false,
      label: (
        <TermsLabelFont>
          본인은 DHL코리아로부터 마케팅 정보를 수신하는 데 동의합니다. 
        </TermsLabelFont>
      ),
    },
  ];

  const plainOptions = checkboxDataList.map((item) => item.value);

  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = ({ target }: CheckboxChangeEvent) => {
    if (target.checked) {
      setCheckedList([...checkedList, target.value]);
    } else {
      setCheckedList(checkedList.filter((item) => item !== target.value));
    }
  };

  useEffect(() => {
    setIndeterminate(!!checkedList.length && checkedList.length < plainOptions.length);
    setCheckAll(checkedList.length === plainOptions.length);
  }, [checkedList, plainOptions]);

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const renderCheckedItem = (item: CheckboxItemInterface) => {
    const isChecked = checkedList.findIndex((checkedValue) => checkedValue === item?.value) > -1;
    return (
      item && (
        <div key={item?.value}>
          <Checkbox onChange={(e) => onChange(e)} checked={isChecked} value={item?.value}>
            {item?.label}
            {next && item.required && !isChecked && <RedFont>{item.validMessage}</RedFont>}
          </Checkbox>
        </div>
      )
    );
  };

  const handleNext = () => {
    isNext(true);
    const requiredList = checkboxDataList.filter((item) => item.required);
    const isValid = requiredList.every((item) => checkedList.includes(item.value));
    if (isValid) {
      setGogeenModalVisible(true); // <-- 다음 버튼 클릭 시 DocumentGogeenInfoModal 표시
    }
  };
  
  return (
    <div>
      <Col>
        <RowItem>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            <TermsLabelFont>본인은 하기 모든 항목에 대하여 동의합니다.</TermsLabelFont>
          </Checkbox>
        </RowItem>
        <RowItem>
          <AllCheckDescriptionFont>
            ※ 귀하는 위와 같은 개인정보 수집•수집을 이용을 거부할 수 있습니다. 다만, 필수적 정보의 수집•이용을 거부할 수 있습니다.
            다만, 필수적 정보의 수집•이용에 동의하지 않을 경우 긴급여권 배송 서비스 계약 체결이 어려울 수 있습니다.
          </AllCheckDescriptionFont>
          {renderCheckedItem(checkboxDataList[0])}
        </RowItem>
        <RowItem>
          <CommonFont>1. 개인정보의 수집 및 이용</CommonFont>
          <br />
          {renderCheckedItem(checkboxDataList[1])}
          <TermsDiv>
            <Termstable>
              <thead>
                <tr style={{ backgroundColor: '#fafafa' }}>
                  <Th>개인정보 수집·항목</Th>
                  <Th>수집·이용 목적</Th>
                  <Th>보유·이용 기간</Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>
                    거주국가 및 공관명, 서류안심서비스 이용 여부, 신청인 성명, 여권발급대상자 성명 및 생년월일, 전화번호, 이메일
                    주소
                  </Td>
                  <Td>여권 배송, 불만처리 등 민원처리, 고지사항 전달</Td>
                  <Pointtd>결제완료 후 4개월까지(법령상 보존의무가 없는 한)</Pointtd>
                </tr>
              </tbody>
            </Termstable>
          </TermsDiv>
          <div style={{ margin: '10px 6vw 5px' }}>
            <AllCheckDescriptionFont>
              ※ 귀하는 위와 같은 개인정보 수집·이용을 거부할 수 있습니다. 다만, 필수적 정보의 수집·이용에 동의하지 않을 경우
              긴급여권 배송 서비스 계약을 체결하고 그 서비스를 제공 받기 어려울 수 있습니다.
            </AllCheckDescriptionFont>
          </div>
          <br />
          {renderCheckedItem(checkboxDataList[2])}
          <TermsDiv>
            <Termstable>
              <thead>
                <tr style={{ backgroundColor: '#fafafa' }}>
                  <Th>제공받는 자</Th>
                  <Th>제공하는 항목</Th>
                  <Th>제공받는 자의 이용목적</Th>
                  <Th>제공받는 자의 보유 및 이용 기간</Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Pointtd>대한민국 외교부 및 외교부 산하 각 재외공관</Pointtd>
                  <Td>
                    거주국가 및 공관명, 서류안심서비스 이용 여부, 신청인 성명, 여권발급대상자 성명 및 생년월일, 전화번호, 이메일
                    주소
                  </Td>
                  <Pointtd>여권 배송, 불만처리 등 민원처리, 고지사항 전달</Pointtd>
                  <Pointtd>목적 달성 시까지(법령상 보존의무가 없는 한)</Pointtd>
                </tr>
              </tbody>
            </Termstable>
          </TermsDiv>
          <br />
          {renderCheckedItem(checkboxDataList[3])}
          <TermsDiv>
            <Termstable>
              <thead>
                <tr style={{ backgroundColor: '#fafafa' }}>
                  <Th>이전받는 자 (정보관리책임자의 연락처)</Th>
                  <Th>이전되는 국가</Th>
                  <Th>이전일시 및 이전방법</Th>
                  <Th>이전되는 개인정보의 항목</Th>
                  <Th>이전받는 자의 개인정보 이용목적</Th>
                  <Th>이전받는 자의 개인정보 보유·이용 기간</Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>
                    <PointSpan>대한민국 외교부 및 외교부 산하 각 재외공관</PointSpan>
                    <br />(<a href='mailto:jhyun07@mofa.go.kr'>jhyun07@mofa.go.kr</a>) <br />* 상세내역은 외교부
                    재외공관설치현황(
                    <a href='http://www.mofa.go.kr/www/pgm/m_4179/uss/emblgbd/emblgbdAdres.do' target='_blank'>
                      링크
                    </a>
                    ) 참조
                  </Td>
                  <Td>서울특별시 종로구 사직로 8길 60 외교부</Td>
                  <Td>정보통신망을 통하여 수시로</Td>
                  <Td>상기 기재 항목 일체</Td>
                  <Pointtd>여권 배송, 불만처리 등 민원처리, 고지사항 전달</Pointtd>
                  <Pointtd>목적 달성 시까지(법령상 보존의무가 없는 한)</Pointtd>
                </tr>
              </tbody>
            </Termstable>
          </TermsDiv>
          <br />
          {renderCheckedItem(checkboxDataList[4])}
        </RowItem>
        <RowItem>
          <CommonFont>2. 마케팅 정보 수신 동의(선택적 항목)</CommonFont>
          <br />
          {renderCheckedItem(checkboxDataList[5])}
          <br />
          {renderCheckedItem(checkboxDataList[6])}
          <TermsDiv>
            <Termstable>
              <thead>
                <tr style={{ backgroundColor: '#fafafa' }}>
                  <Th>수집항목</Th>
                  <Th>수집·이용목적</Th>
                  <Th>보유·이용기간</Th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>
                  위와 같이 수집한 고객님의 <br></br> 개인정보 일체
                  </Td>
                  <Pointtd>이메일, 전화, 우편, 문자메시지, 모바일 메신저 등을 통한 서비스 또는 상품 정보, DHL 뉴스레터, 이벤트 초대 등 광고성 정보의 전달 및 설문조사 전송</Pointtd>
                  <Pointtd>결제완료 후 4개월까지(법령상 보존의무가 없는 한)</Pointtd>
                </tr>
                <tr>
                  <td colSpan={3} style={{ fontSize: '18px' }}>
                    
                  </td>
                </tr>
             </tbody>
            </Termstable>
          </TermsDiv>
        </RowItem>
        <RowItem>
          <TermsDiv style={{ fontSize: '18px' }}>
          귀하는 상기 기재한 개인정보 수집 및 이용에 대해 동의하지 않더라도 긴급여권 특급배송 서비스를 이용할 수 있습니다. 다만, 동의 거부 시에는 당사로부터 유용한 정보를 받지 못할 수 있습니다.
        </TermsDiv>
        </RowItem>
        <RowItem>
          <CommonFont>※	개인정보의 처리위탁</CommonFont>
          <TermsDiv>
            <Termstable>
              <thead>
                <tr style={{ backgroundColor: '#fafafa' }}>
                  <Th>수탁업체</Th>
                  <Th>위탁하는 업무의 내용</Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Pointtd>
                  크레인즈커뮤이케이션
                  </Pointtd>
                  <Td style={{ fontSize: '18px' }}>소셜미디어 계정 운영 및 관리, 이벤트 진행 업무(이벤트 당첨자 경품발송 등)</Td>
                </tr>
                <tr>
                  <Pointtd>
                  퍼플아이앤씨
                  </Pointtd>
                  <Td style={{ fontSize: '18px' }}>온라인 이벤트 당첨자 경품 발송</Td>
                </tr>
                <tr>
                  <Pointtd>
                  한진정보통신
                  </Pointtd>
                  <Td style={{ fontSize: '18px' }}>문자메시지, 카카오톡 문자 등의 발송</Td>
                </tr>
                <tr>
                  <Pointtd>
                  ION, DotDigital, Marketo
                  </Pointtd>
                  <Td style={{ fontSize: '18px' }}>이벤트 웹사이트 호스팅, 인터넷 플랫폼 서비스 제공 업무</Td>
                </tr>
                <tr>
                  <Pointtd>
                  Survalyzer
                  </Pointtd>
                  <Td style={{ fontSize: '18px' }}>설문조사 업무</Td>
                </tr>
             </tbody>
            </Termstable>
          </TermsDiv>
        </RowItem>
      </Col>
      <Row style={{ paddingBottom: 20 }} justify={'center'}>
        <YellowButton onClick={handleNext}>다음</YellowButton>
      </Row>
      <HorizontalBlank height={15} />
      <DocumentGogeenInfoModal visible={gogeenModalVisible} onClose={() => setGogeenModalVisible(false)} onConfirm={handleGogeenConfirm} />
      <DocumentSafeInfoModal visible={safeModalVisible} onClose={() => setSafeModalVisible(false)} checkedList={checkedList} confirmValue={confirmValue} />
    </div>
  );
};
const Termstable = styled.table`
  width: 90%;
  max-width: 90%;
  margin-bottom: 10px;
  box-shadow: rgb(60, 72, 88) 0px 2.5px 10px -2.5px;
  word-break: keep-all;
  margin: 10px 6vw 5px;
  @media only screen and (max-width: 767px) {
    table-layout: fixed;
    margin: 10px 0px 0px 0px !important;
    width: 180%;
    max-width: 180%;
    word-break: break-all;
  }
`;

const TermsDiv = styled.div`
  @media only screen and (max-width: 767px) {
    overflow-x: auto;
  }
`;

const Th = styled.th`
  padding: 10px;
  text-align: center;
  @media only screen and (max-width: 767px) {
    font-size: 12px !important;
  }
`;

const Td = styled.td`
  padding: 10px;
  text-align: center;
  @media only screen and (max-width: 767px) {
    font-size: 12px !important;
  }
`;

const Pointtd = styled(Td)`
  font-size: 17px;
  text-decoration: underline;
  @media only screen and (max-width: 767px) {
    font-size: 12px !important;
  }
`;

const PointSpan = styled.span`
  font-size: 17px;
  text-decoration: underline;
  @media only screen and (max-width: 767px) {
    font-size: 12px !important;
  }
`;

const TableContainer = styled(Table)`
  margin: 10px 6vw 5px;
  @media only screen and (max-width: 767px) {
    margin: 10px 0 5px;
    width: 100%;
  }
`;

const BlueFont = styled.span`
  color: blue;
`;

const CommonFont = styled.span`
  color: rgb(60, 72, 88);
  font-size: 16px;
  @media only screen and (max-width: 767px) {
    font-size: 12px !important;
  }
`;

const AllCheckDescriptionFont = styled(CommonFont)`
  font-size: 13.5px;
  @media only screen and (max-width: 767px) {
    font-size: 11.5px;
  }
  margin: 5px 0 15px;
`;

const TermsLabelFont = styled.span`
  font-size: 16px;
  @media only screen and (max-width: 767px) {
    font-size: 12px !important;
  }
  color: black;
`;

const RowItem = styled.div`
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
`;

const TermsTable = styled.table`
  width: 70%;
  max-width: 80%;
  margin-bottom: 10px;
  box-shadow: rgb(60, 72, 88) 0px 2.5px 10px -2.5px;
  word-break: keep-all;
  margin: 10px 6vw 5px;
  @media only screen and (max-width: 767px) {
    font-size: 12px !important;
    margin: 10px 0 5px;
    width: 100%;
  }
`;

const RedFont = styled.div`
  color: red;
`;
export default CheckTerms;

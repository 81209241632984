import { Button } from 'antd';
import styled from 'styled-components';

export const YellowButton = styled(Button)`
  background: linear-gradient(to left, #ffcc00, #ffe100) !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  height: 50px !important;
  width: 120px !important;
  border-radius: 10px !important;
  border: 0px !important;
  border-bottom: 3px solid #d40511 !important;
  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(to left, #ffcc00, #ffe100) !important;
  }
  &:hover {
    border: 0px !important;
  }
  @media only screen and (max-width: 767px) {
    font-size: 14px !important;
    height: 40px !important;
    width: 100px !important;
  }
`;

import { API } from '@apis/API';
import { useRequest } from 'src/axios/useRequest';
export interface EpadmRequestInterface {
  dhlIdx?: number;
  pgIdx?: number;
}
export interface EpadmResponseInterface {
  dhlIdx: number;
  pgIdx: number;
  CardName: string;
  ResultErrCode: string;
  appldate: string;
  applnum: string;
  appltime: string;
  bemail: string;
  bname: string;
  bnameList: string;
  btel: string;
  cardBankcode: string;
  cardCode: string;
  cardName: string;
  cardNum: string;
  cardissuercode: string;
  destName: string;
  destStation: string;
  dhlSysdate: string;
  embassyName: string;
  goodname: string;
  hawbIb: string;
  hawbOb: string;
  payCancelDate: string;
  payCancelP2Date: string;
  payCancelPDate: string;
  payCancelRDate: string;
  pgCancelIdx: string;
  pgCancelP2Idx: string;
  pgCancelP2Tid: string;
  pgCancelPIdx: string;
  pgCancelPTid: string;
  pgCancelPYn: string;
  pgCancelPYn2: string;
  pgCancelRIdx: string;
  pgCancelRYn2: string;
  pgCancelYn: string;
  pgRIdx: string;
  pgRYn: string;
  price: string;
  ptype: string;
  ref: string;
  regDate: string;
  rejectDate: string;
  resultErrCode: string;
  resultcode: string;
  resultmsg: string;
  tid: string;
  tno: string;
}

export const useEpadmData = ({ dhlIdx, pgIdx }: EpadmRequestInterface) => {
  const { endpoint, method } = API.RECEIPE_DETAIL;
  return useRequest<EpadmResponseInterface>(
    dhlIdx && pgIdx
      ? {
          url: endpoint,
          params: { dhlIdx, pgIdx },
          method,
        }
      : null,
    {
      shouldRetryOnError: false,
    },
  );
};

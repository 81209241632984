import { Button } from 'antd';
import { FC, useState } from 'react';
import { IFooterProps } from './Footer';

const PcPaymentTemplates: FC<Partial<IFooterProps>> = ({ param, bnameList, orderId }) => {
  //console.log('payment', param);
  const [loading, isLoading] = useState(false);
  const handleSubmit = () => {
    //alert(process.env.REACT_APP_PAY_SITE_CD);
    //alert(orderId);
    //debugger;
    const form = document.getElementById('paymentForm');
    isLoading(true);
    //debugger;
    // @ts-ignore: Unreachable code error
    KCP_Pay_Execute(form);
  };

  return (
    <>
      <Button loading={loading} onClick={handleSubmit}>
        결제하기
      </Button>
      <form id='paymentForm' action={process.env.REACT_APP_PAYMENT_URL + '/dhl_cli_hub.jsp'} method='post'>
        {/* <input type='hidden' name='cityListSelect' value={'BO'} />
        <input type='hidden' name='embassyListSelect' value={'BV'} /> */}
        <input type='hidden' name='cityListSelect' value={param?.ctryCode} />
        <input type='hidden' name='embassyListSelect' value={param?.embassyCode} />
        <input type='hidden' name='serviceName' value={param?.serviceName} />
        <input type='hidden' name='totalAmount' value={param?.totalAmount ?? 0} />
        <input type='hidden' name='bname' value={param?.buyerName} />
        <input type='hidden' name='pay_method' value='100000000000' />
        <input type='hidden' name='btel' value={param?.phoneNumber} />
        <input type='hidden' name='bemail' value={param?.email} />
        <input type='hidden' name='orderid' value={orderId} />
        <input type='hidden' name='ordr_idxx' value={orderId} />
        <input type='hidden' name='req_tx' value='pay' />
        <input type='hidden' name='site_cd' value={process.env.REACT_APP_PAY_SITE_CD} />
        <input type='hidden' name='site_logo' value={process.env.REACT_APP_PAYMENT_URL + '/DHL_logo.gif'} />
        <input type='hidden' name='site_name' value='KCP DHL SHOP' />
        <input type='hidden' name='quotaopt' value='0' />
        <input type='hidden' name='currency' value='WON' />
        <input type='hidden' name='module_type' value='01' />
        <input type='hidden' name='res_cd' value='' />
        <input type='hidden' name='res_msg' value='' />
        <input type='hidden' name='enc_info' value='' />
        <input type='hidden' name='good_mny' value={param?.totalAmount ?? 0} />
        <input type='hidden' name='enc_data' value='' />
        <input type='hidden' name='ret_pay_method' value='' />
        <input type='hidden' name='tran_cd' value='' />
        <input type='hidden' name='ordr_chk' value='' />
        <input type='hidden' name='cash_yn' value='' />
        <input type='hidden' name='cash_tr_code' value='' />
        <input type='hidden' name='cash_id_info' value='' />
        <input type='hidden' name='good_expr' value='0' />
        <input type='hidden' name='good_name' value={param?.serviceName} />
        <input type='hidden' name='bnameList' value={bnameList} />
        <input type='hidden' name='isMktAgree' value={param?.isMktAgree ? 'Y' : 'N'} />
        <input type='hidden' name='insurcYn'  value={param?.insurcYn} />
        <input type='hidden' name='gogreenYn' value={param?.gogreenYn} />
      </form>
    </>
  );
};

export default PcPaymentTemplates;

import HorizontalBlank from '@pages/components/common/atoms/HorizontalBlank';
import { Modal } from 'antd';
import { FC, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import ForeignMinistry from './ForeignMinistry';
import PaymentHistory from './PaymentHistory';
import PaymentStatus from './PaymentStatus';
import RePaymentHistory from './RePaymentHistory';

interface EpadmModalInterface {
  dhlIdx?: number;
  pgIdx?: number;
  visible: boolean;
  onClose: () => void;
}

const EpadmModal: FC<EpadmModalInterface> = ({ visible, dhlIdx, pgIdx, onClose }) => {
  const printAreaRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printAreaRef.current,
  });
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={'결제 취소 요청'}
      width={700}
      onOk={handlePrint}
      okText={'결제내역 인쇄'}
      cancelText={'창닫기'}>
      <div ref={printAreaRef}>
        <HeaderTitle>외교부 긴급여권 배송 서비스 - 외교부회</HeaderTitle>
        <HorizontalBlank height={40} />
        <PaymentStatus dhlIdx={dhlIdx} pgIdx={pgIdx} />
        <HorizontalBlank height={40} />
        <PaymentHistory dhlIdx={dhlIdx} pgIdx={pgIdx} />
        <RePaymentHistory dhlIdx={dhlIdx} pgIdx={pgIdx} />
        <HorizontalBlank height={40} />
        <ForeignMinistry dhlIdx={dhlIdx} pgIdx={pgIdx} />
      </div>
    </Modal>
  );
};

const HeaderTitle = styled.div`
  background: #d40511;
  color: white;
  font-weight: bold;
  width: 80%;
  height: 40px;
  border-bottom: solid 10px #ffcc00;
  display: flex;
  align-items: center;
`;

export default EpadmModal;

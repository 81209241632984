import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { signin, signinCerti, signinCertiConfirm, signinCertiEmail } from '@apis/sign';
import EmbassyCtrySelect from '@pages/home/templates/EmbassyCtrySelect';
import EmbassySelect from '@pages/home/templates/EmbassySelect';
import { Button, Form, Input, Select, Space, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Password from 'antd/lib/input/Password';
import Modal from 'antd/lib/modal/Modal';
import sha256 from 'crypto-js/sha256';
import { FC, useEffect, useState } from 'react';
import useCountDown from 'react-countdown-hook';
import styled from 'styled-components';

interface JoinModalInterface {
  visible: boolean;
  onClose: () => void;
}

export const milisecondToFormat = (milisecond: number) => {
  const second = milisecond / 1000;
  const minute = Math.floor(second / 60);
  const minuteStr = `${minute}`.padStart(2, '0');
  const leftSecond = second % 60;
  const secondStr = `${leftSecond}`.padStart(2, '0');
  return `${minuteStr}:${secondStr}`;
};

const JoinModal: FC<JoinModalInterface> = ({ visible, onClose }) => {
  const [certi, isCerti] = useState<boolean>(false);
  const [certiConfirm, isCertiConfirm] = useState<boolean>(false);
  const initialTime = 1000 * 60 * 3;
  const interval = 1000;
  const [timeLeft, { start, pause, reset }] = useCountDown(initialTime, interval);
  const [ctryCode, setCtryCode] = useState<string>();
  const [auth, setAuth] = useState<string>();
  const [form] = useForm();

  const handleUpdate = async (submitData: any) => {
    const parma = {
      ...submitData,
      phoneNumber: submitData.phoneNumber ? `${submitData.prefix ?? ''}${submitData.phoneNumber}` : '',
    };
    const cpwEncrytion = sha256(submitData?.password).toString();
    try {
      delete submitData?.password;
      const res = await signin({ ...parma, cpwEncrytion });
      if (res?.status === 200) {
        message.success('회원가입에 성공하였습니다. 재로그인 하시기 바랍니다.');
        onClose();
      }
    } catch (e: any) {
      const errorResponse = e?.response;
      message.error(
        errorResponse?.data?.message
          ? errorResponse?.data?.message
          : errorResponse?.statusText
          ? errorResponse?.statusText
          : '시스템에러. 관리자에게 문의하세요.',
      );
    }
  };

  const phoneCertiHandle = async () => {
    const param = await form.validateFields(['auth', 'phoneNumber', 'cname', 'cid', 'prefix', 'ctry', 'embassyCode']);
    try {
      await signinCerti({
        ...param,
        auth: param.auth,
        phoneNumber: param.phoneNumber ? `${param.prefix ?? ''}${param.phoneNumber}` : '',
      });
      isCerti(true);
    } catch (e: any) {
      const errorResponse = e?.response;
      message.error(
        errorResponse?.data?.message
          ? errorResponse?.data?.message
          : errorResponse?.statusText
          ? errorResponse?.statusText
          : '시스템에러. 관리자에게 문의하세요.',
      );
    }
  };

  const emailCertiHandle = async () => {
    const param = await form.validateFields(['auth', 'email', 'cname', 'cid', 'prefix', 'ctry', 'embassyCode']);
    try {
      await signinCertiEmail({
        ...param,
        auth: param.auth,
        email: param.email,
      });
      isCerti(true);
    } catch (e: any) {
      const errorResponse = e?.response;
      message.error(
        errorResponse?.data?.message
          ? errorResponse?.data?.message
          : errorResponse?.statusText
          ? errorResponse?.statusText
          : '시스템에러. 관리자에게 문의하세요.',
      );
    }
  };

  const phoneCertiConfirmHandle = async () => {
    const param = await form.validateFields(['phoneNumber', 'cname', 'cid', 'pinNumber']);
    try {
      await signinCertiConfirm({ ...param, phoneNumber: param.phoneNumber ? `${param.prefix ?? ''}${param.phoneNumber}` : '' });
      isCertiConfirm(true);
    } catch (e: any) {
      const errorResponse = e?.response;
      message.error(
        errorResponse?.data?.message
          ? errorResponse?.data?.message
          : errorResponse?.statusText
          ? errorResponse?.statusText
          : '시스템에러. 관리자에게 문의하세요.',
      );
    }
  };

  useEffect(() => {
    certi && start();
  }, [certi, start]);

  useEffect(() => {
    if (certiConfirm) {
      message.success('인증확인 완료되었습니다.');
      pause();
    }
  }, [certiConfirm, pause]);

  return (
    <Modal
      afterClose={() => {
        isCertiConfirm(false);
        isCerti(false);
        reset();
        form.resetFields();
        setAuth('E');
      }}
      destroyOnClose={true}
      maskClosable={false}
      title={
        <Space>
          <ExclamationCircleTwoTone twoToneColor={'#D40511'} />
          회원가입 약관
        </Space>
      }
      visible={visible}
      okText={'확인'}
      cancelText={'다시입력'}
      onCancel={onClose}
      footer={[]}>
      <Form
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        initialValues={{ auth: 'E' }}
        onFinish={handleUpdate}
        onValuesChange={(data, data2) => {
          if (data?.auth) {
            setAuth(data.auth);
            form.resetFields(['ctry', 'embassyCode']);
          }
        }}>
        <Form.Item
          label='권한타입'
          name='auth'
          rules={[
            {
              required: true,
              message: '권한을 선택해주세요.',
            },
          ]}>
          <Select
            style={{ minWidth: '100%', width: 100 }}
            options={[
              { label: '외교부', value: 'M' },
              { label: '해외공관담당자', value: 'E' },
            ]}></Select>
        </Form.Item>
        {auth !== 'M' && (
          <>
            <EmbassyCtrySelect
              isIgnoreAuth
              onChange={setCtryCode}
              label='국가'
              rules={[
                {
                  required: true,
                  message: '국가를 선택해주세요.',
                },
              ]}
            />
            <>
              {ctryCode && (
                <EmbassySelect
                  isIgnoreAuth
                  ctryCode={ctryCode}
                  label='공관'
                  rules={[
                    {
                      required: true,
                      message: '공관을 선택해주세요.',
                    },
                  ]}
                />
              )}
            </>
          </>
        )}
        <Form.Item
          label='ID'
          name='cid'
          rules={[
            {
              required: true,
              message: 'ID를 입력해주세요.',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label='이름'
          name='cname'
          rules={[
            {
              required: true,
              message: '이름을 입력해주세요.',
            },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label='비밀번호'
          name='password'
          rules={[
            {
              required: true,
              message: '비밀번호를 입력해주세요.',
            },
            {
              validator: (_, value) => {
                const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/;

                if (reg.test(value) || !value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error('비밀번호는 최소 10자이상, 문자와 숫자와 특수문자를 최소 1자 이상 입력해주세요.'),
                  );
                }
              },
            },
          ]}>
          <Password />
        </Form.Item>
        <Form.Item
          label='비밀번호재입력'
          name='newPassword'
          rules={[
            {
              required: true,
              message: '비밀번호를 재입력해주세요.',
            },
            {
              validator: (_, value) => {
                const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/;

                if (reg.test(value) || !value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    new Error('비밀번호는 최소 10자이상, 문자와 숫자와 특수문자를 최소 1자 이상 입력해주세요.'),
                  );
                }
              },
            },
            {
              validator: (_, value) => {
                const password = form.getFieldValue('password');
                if (value === password) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error('비밀번호가 일치하지 않습니다. 재입력하시기 바랍니다.'));
                }
              },
            },
          ]}>
          <Password />
        </Form.Item>
           <Form.Item
           label='이메일'
           name='email'
           rules={[
             {
               required: true,
               message: '이메일을 입력해주세요.',
             },
           ]}>
           <Input.Search
             style={{
               width: '100%',
             }}
             enterButton={'이메일인증'}
             //suffix={milisecondToFormat(timeLeft)}
             onSearch={emailCertiHandle}
             // addonAfter={<Button>번호인증</Button>}
           />
         </Form.Item>
                
        {certi && (
          <Form.Item
            label='인증번호'
            name='pinNumber'
            rules={[
              {
                required: true,
                message: '인증번호를 입력해주세요.',
              },
            ]}>
            <Input.Search disabled={certiConfirm} enterButton={'인증 확인'} onSearch={phoneCertiConfirmHandle} />
          </Form.Item>
        )}

        <ButtonArea
          wrapperCol={{
            offset: 9,
            span: 18,
          }}>
          <Space>
            <Button type={'primary'} htmlType='submit' disabled={!certiConfirm}>
              확인
            </Button>
            <Button onClick={onClose}>닫기</Button>
          </Space>
        </ButtonArea>
      </Form>
    </Modal>
  );
};

const ButtonArea = styled(Form.Item)`
  width: 100%;
  justify-content: flex-end;
`;

export default JoinModal;

import { API } from '@apis/API';
import { GlobalFormat } from '@constants/Format';
import { sortString } from '@utils/sort';
import { getStorageItem, STORAGE_KEY } from '@utils/storage';
import { Button, Col, Row, Table } from 'antd';
import useModal from 'antd/lib/modal/useModal';
import moment from 'moment';
import { FC, useState } from 'react';
import { axiosInstance, useRequest } from 'src/axios/useRequest';
import styled from 'styled-components';
import EpadmModal from './EpadmModal';
import { SearchDataInterface } from './SearchForm';
import UpdateHawbOb from './UpdateHawbOb';

export interface PaymentListItemInterface {
  acctIb: string;
  acctOb: string;
  bemail: string;
  bname: string;
  bnameList: string;
  btel: string;
  dest: string;
  destName: string;
  destStation: string;
  dhlIdx: number;
  dhlZone: string;
  embassyCode: string;
  embassyName: string;
  hawbIb: string;
  hawbNew: string;
  hawbOb: string;
  invNoIb: string;
  invNoOb: string;
  modDate: string;
  modUsr: string;
  payCancelDate: string;
  payCancelP2Date: string;
  payCancelPDate: string;
  payCancelRDate: string;
  payDate: string;
  payRDate: string;
  pgCancelIdx: number;
  pgCancelP2Idx: number;
  pgCancelPIdx: number;
  pgCancelRIdx: number;
  pgIdx: number;
  pgRIdx: number;
  ptype: string;
  ref: string;
  regDate: string;
  rejectDate: string;
  rejectYn: YN;
  tid: string;
  tno: string;
}

const maskPersonalInfo = (info: string | null | undefined) => {
  return info ? '***' : '-';
};

const PaymentList: FC<SearchDataInterface> = (params) => {
  const [modal, contextHolder] = useModal();
  const { endpoint, method } = API.PAYMENT_LIST;
  const { data, error, mutate } = useRequest<PaymentListItemInterface[]>({
    url: endpoint,
    params: { ...params, ref: params.reference },
    method,
  });

  const [epadmVisible, isEpadmVisible] = useState(false);
  const [dhlIdx, setDhlIdx] = useState<number>();
  const [pgIdx, setPgIdx] = useState<number>();
  const [visibleRecord, setVisibleRecord] = useState<number | null>(null);

  if (error) {
    return <div>error..</div>;
  }

  const handleSubmit = (dhlIdx: number) => {
    const userInfo: UserInfo = getStorageItem(STORAGE_KEY.USER_INFO);
    const { endpoint } = API.UPDATE_PAYMENT_REJECT;
    axiosInstance.post(endpoint, {
      dhlIdx,
      loginUser: userInfo?.username,
    });
    mutate();
  };

  const handleViewClick = (index: number) => {
    setVisibleRecord(index === visibleRecord ? null : index);
  };

  return (
    <>
      <Table
        loading={!data}
        dataSource={data}
        rowKey={(record) => `${record.dhlIdx}${record.pgIdx}`}
        columns={[
          {
            title: '#',
            dataIndex: '',
            render: (pgIdx, record, index) => index,
            rowSpan: 2,
          },
          {
            title: '결제구분',
            dataIndex: 'ref',
            render: (ref: string) => {
              if (ref.startsWith('O')) {
                return '무통장';
              } else if (ref) {
                return '카드결제';
              } else {
                return <WaitingPayment>무통장 입금대기</WaitingPayment>;
              }
            },
          },
          {
            title: '예약번호',
            dataIndex: 'ref',
          },
          {
            title: 'EXT',
            dataIndex: 'insurcYn',
          },
          {
            title: 'DHL운송장번호',
            dataIndex: 'ptype',
            render: (ptype, record) => {
              return (
                <>
                  <UpdateHawbOb data={record} mutate={mutate} />
                </>
              );
            },
          },
          {
            title: '국가',
            sorter: (a, b) => sortString(a.destName, b.destName),
            dataIndex: 'destName',
          },
          {
            title: '공관',
            sorter: (a, b) => sortString(a.embassyName, b.embassyName),
            dataIndex: 'embassyName',
          },
          {
            title: (
              <Col>
                <Row>신청인</Row>
                <Row>민원인</Row>
              </Col>
            ),
            dataIndex: 'bname',
            render: (bname, record, index) => (
              <Col>
                <Row>
                  {visibleRecord === index ? bname : maskPersonalInfo(bname)}
                </Row>
                <Row>
                  {visibleRecord === index
                    ? record.bnameList
                    : maskPersonalInfo(record.bnameList)}
                </Row>
              </Col>
            ),
          },
          {
            title: (
              <Col>
                <Row>전화</Row>
                <Row>이메일</Row>
              </Col>
            ),
            dataIndex: 'btel',
            render: (btel, record, index) => (
              <Col>
                <Row>
                  {visibleRecord === index ? btel : maskPersonalInfo(btel)}
                </Row>
                <Row>
                  {visibleRecord === index
                    ? record.bemail
                    : maskPersonalInfo(record.bemail)}
                </Row>
              </Col>
            ),
          },
          {
            title: (
              <Col>
                <Row>결제</Row>
                <Row>취소</Row>
              </Col>
            ),
            dataIndex: 'payDate',
            render: (payDate, record) => {
              const { ref } = record;
              return (
                <Col>
                  <Row>
                    {record.payCancelDate
                      ? moment(record.payCancelDate).format('YYYY-MM-DD SS:MM')
                      : '-'}
                  </Row>
                </Col>
              );
            },
          },
          {
            title: '환불 대상자',
            dataIndex: 'rejectYn',
            render: (rejectYn, record) => {
              const {
                ptype,
                pgCancelIdx,
                rejectDate,
                embassyName,
                bname,
                dhlIdx,
              } = record;
              if (rejectYn === 'Y') {
                return moment(rejectDate).format(GlobalFormat.DateDash);
              } else if (ptype !== 'I' && !pgCancelIdx) {
                return (
                  <Button
                    onClick={() => {
                      modal.confirm({
                        onOk: () => handleSubmit(dhlIdx),
                        title: '환불 대상자 등록',
                        content: (
                          <div>
                            <p>{`공 관 : ${embassyName}`}</p>
                            <p>{`신청인: ${bname}`}</p>
                            <p>환불대상자 업데이트를 하시겠습니까?</p>
                          </div>
                        ),
                      });
                    }}>
                    등록
                  </Button>
                );
              }
            },
          },
          {
            title: 'Actions',
            dataIndex: '',
            render: (_, __, index) => (
              <Button onClick={() => handleViewClick(index)}>
                {visibleRecord === index ? 'Hide' : 'View'}
              </Button>
            ),
          },
        ]}
      />
      <EpadmModal
        visible={epadmVisible}
        dhlIdx={dhlIdx}
        pgIdx={pgIdx}
        onClose={() => {
          isEpadmVisible(false);
        }}
      />
      {contextHolder}
    </>
  );
};

const WaitingPayment = styled.span`
  color: #cc0000;
`;

export default PaymentList;

import { EpadmRequestInterface, useEpadmData } from '@apis/epadm';
import CommonSpinner from '@pages/components/common/atoms/CommonSpiner';
import { Descriptions } from 'antd';
import Item from 'antd/lib/descriptions/Item';
import React, { FC, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

const PaymentHistory: FC<EpadmRequestInterface> = ({ dhlIdx, pgIdx }) => {
  const { data, error } = useEpadmData({ dhlIdx, pgIdx });

  const 편도 = useMemo(() => {
    return data?.pgCancelYn === 'Y' && !data?.pgCancelPYn2;
  }, [data?.pgCancelYn, data?.pgCancelPYn2]);

  const 운임비 = useMemo(() => {
    const 완전취소 = !data?.pgCancelYn && data?.pgCancelPYn2 === 'Y';
    const 부분취소 = !data?.pgCancelYn && !data?.pgCancelPYn2 && data?.pgCancelPYn === 'Y';
    const price = <NumberFormat value={data?.price} displayType={'text'} thousandSeparator={true} suffix={' 원'} />;
    if (완전취소) {
      return (
        <>
          <Font color={'#cc0000'}>{`${price ?? 0 / 2} 부분취소`}</Font>
          <Font color={'#cc0000'}>{`${price ?? 0 / 2} 완전취소`}</Font>
          <Font color={'blue'}>{`최종 0 원 결제`}</Font>
        </>
      );
    } else if (부분취소) {
      <>
        <Font color={'#cc0000'}>{`${price ?? 0 / 2} 부분취소`}</Font>
        <Font color={'#cc0000'}>{`${price ?? 0 / 2} 원 결제`}</Font>
      </>;
    } else {
      return price;
    }
  }, [data?.pgCancelYn, data?.pgCancelPYn, data?.pgCancelPYn2, data?.price]);

  if (error) {
    <div>error..</div>;
  } else if (!data) {
    return <CommonSpinner />;
  }
  return (
    <Descriptions title={'결제내역'} bordered column={1}>
      <Item label={'DHL 상품명'}>
        <Font color={편도 ? '#cc0000' : undefined}>{편도 ? '편도' : data?.goodname}</Font>
      </Item>
      <Item label={'예약번호'}>{data?.ref}</Item>
      <Item label={'DHL운임'}>{운임비}</Item>
      <Item label={'승인번호'}>{data?.applnum}</Item>
      <Item label={'카드종류'}>{data?.cardName}</Item>
      <Item label={'카드발급사'}>{data?.cardissuercode}</Item>
    </Descriptions>
  );
};

const Font = styled.span<{ color?: string; bold?: boolean }>`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ bold }) =>
    bold &&
    css`
      font-wight: bold;
    `}
`;

export default PaymentHistory;

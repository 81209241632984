import { Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import EmbassyCtrySelect from './EmbassyCtrySelect';
import EmbassySelect from './EmbassySelect';

export interface EmbassyCtryInterface {
  ctryCode: string;
  ctryName: string;
  ctryZone: string;
  embassyCode: string;
  embassyEName: string;
  embassyName: string;
  onlineYn: YN;
  stationCode: string;
  favoriteYn: YN;
}

interface InitValueProps {
  initCtryCode?: string;
  initEmbassyCode?: string;
}

const SelectCountry: FC<InitValueProps> = ({ initCtryCode, initEmbassyCode }) => {
  const [ctryCode, setCtryCode] = useState<string | undefined>(initCtryCode);
  const [embassyCode, setEmbassyode] = useState<string | undefined>(initEmbassyCode);

  useEffect(() => {
    if (initCtryCode) {
      setCtryCode(initCtryCode);
    }
  }, [initCtryCode]);

  useEffect(() => {
    if (initEmbassyCode) {
      setEmbassyode(initEmbassyCode);
    }
  }, [initEmbassyCode]);

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <EmbassyCtrySelect onChange={setCtryCode} />
      {ctryCode && <EmbassySelect ctryCode={ctryCode} embsyCode={embassyCode || ''} />}
    </Space>
  );
};

export default SelectCountry;

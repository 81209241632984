import { EpadmRequestInterface, useEpadmData } from '@apis/epadm';
import { GlobalFormat } from '@constants/Format';
import CommonSpinner from '@pages/components/common/atoms/CommonSpiner';
import { Button, Descriptions, Row, Space } from 'antd';
import Item from 'antd/lib/descriptions/Item';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReceiptModal from './ReceiptModal';

const PaymentStatus: FC<EpadmRequestInterface> = ({ dhlIdx, pgIdx }) => {
  const { data, error } = useEpadmData({ dhlIdx, pgIdx });
  const 결제내역 = useMemo(() => {
    const success = data?.resultcode === '00';
    if (success) {
      return (
        data?.appldate &&
        data?.appltime &&
        `결제 성공 - ${moment(`${data?.appldate} ${data?.appltime}`).format(GlobalFormat.DateHourMinute)}`
      );
    } else {
      return `결제 실패 - ${data?.resultmsg}`;
    }
  }, [data?.resultcode, data?.appldate, data?.appltime, data?.resultmsg]);

  const [visible, isVisible] = useState(false);
  const [tno, setTno] = useState<string>();
  const receipt = (tno: string) => {
    let receiptUrl = `https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=${tno}&noMethod=1`;
    window.open(receiptUrl, 'receipt', 'width=430,height=700, scrollbars=yes,resizable=yes');
  };

  if (error) {
    <div>error..</div>;
  } else if (!data) {
    return <CommonSpinner />;
  }

  return (
    <div>
      <Descriptions title={'결제상태'} bordered column={1}>
        <Item label={'결제결과'}>
          <Row justify={'space-between'}>
            <span>{결제내역}</span>
            <Button
              onClick={() => {
                setTno(data?.tno);
                isVisible(true);
              }}>
              영수증 출력
            </Button>
          </Row>
        </Item>
        {data?.payCancelPDate && (
          <Item label={'부분취소결과'}>
            <Row justify={'space-between'}>
              <Space direction={'horizontal'}>
                <BlueFont>{'부분취소'}</BlueFont>
                <span>{data?.payCancelPDate && moment(data?.payCancelPDate).format(GlobalFormat.DateHourMinute)}</span>
              </Space>
              <Button onClick={() => receipt(data.pgCancelPTid)}>영수증 출력</Button>
            </Row>
          </Item>
        )}
        {data?.payCancelP2Date && (
          <Item label={'완전취소결과'}>
            <Row justify={'space-between'}>
              <Space direction={'horizontal'}>
                <BlueFont>{'완전취소'}</BlueFont>
                <span>{data?.payCancelP2Date && moment(data?.payCancelP2Date).format(GlobalFormat.DateHourMinute)}</span>
              </Space>
              <Button onClick={() => receipt(data.pgCancelP2Tid)}>영수증 출력</Button>
            </Row>
          </Item>
        )}
        {data?.pgCancelYn === 'Y' && (
          <Item label={'취소결과'}>
            <Space direction={'horizontal'}>
              <BlueFont>{'결제취소'}</BlueFont>
              <span>{data?.payCancelDate && moment(data?.payCancelDate).format(GlobalFormat.DateHourMinute)}</span>
            </Space>
          </Item>
        )}
      </Descriptions>
      <ReceiptModal
        visible={visible}
        tno={tno}
        onClose={() => {
          isVisible(false);
        }}
      />
    </div>
  );
};

const BlueFont = styled.span`
  color: blue;
`;

export default PaymentStatus;

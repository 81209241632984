import { axiosInstance } from 'src/axios/useRequest';
import { API } from './API';

interface signinRequestInterface {
  cid: string;
  cname: string;
  cpwEncrytion: string;
  phoneNumber: string;
}

interface signinCertiRequestInterface {
  cid?: string;
  cname?: string;
  phoneNumber?: string;
  pinNumber?: string;
  password?: string;
  userId?: string;
  localApp?: string;
  email?: string;
}

export const signin = async (params: signinRequestInterface) => {
  const { endpoint } = API.SIGNIN;
  return await axiosInstance.post(endpoint, params);
};
export const signinCerti = async (params: signinCertiRequestInterface) => {
  const { endpoint } = API.SIGNIN_CERTI;
  return await axiosInstance.post(endpoint, params);
};
export const signinCertiEmail = async (params: signinCertiRequestInterface) => {
  const { endpoint } = API.SIGNIN_CERTI_EMAIL;
  return await axiosInstance.post(endpoint, params);
};
export const loginCerti = async (params: signinCertiRequestInterface) => {
  const { endpoint } = API.LOGIN_CERTI;
  return await axiosInstance.post(endpoint, params);
};
export const loginCertiEmail = async (params: signinCertiRequestInterface) => {
  const { endpoint } = API.LOGIN_CERTI_EMAIL;
  return await axiosInstance.post(endpoint, params);
};
export const signinCertiConfirm = async (params: signinCertiRequestInterface) => {
  const { endpoint } = API.SIGNIN_CERTI_CONFIRM;
  return await axiosInstance.post(endpoint, params);
};
export const loginCertiConfirm = async (params: signinCertiRequestInterface) => {
  const { endpoint } = API.LOGIN_CERTI_CONFIRM;
  return await axiosInstance.post(endpoint, params);
};

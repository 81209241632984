import { ExclamationCircleTwoTone, SnippetsOutlined } from '@ant-design/icons';
import { Checkbox, Space, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { FC, useState } from 'react';
import styled from 'styled-components';
import HorizontalBlank from '../atoms/HorizontalBlank';

interface EmailTermsModalInterface {
  visible: boolean;
  onClose: () => void;
  onOk: () => void;
}

const EmailTermsModal: FC<EmailTermsModalInterface> = ({ visible, onClose, onOk }) => {
  const [checked, isChecked] = useState(false);
  return (
    <Modal
      destroyOnClose={true}
      width={1000}
      title={
        <Space>
          <SnippetsOutlined twoToneColor={'#D40511'} />
          긴급여권 조회 사용 약관
        </Space>
      }
      visible={visible}
      okText={'동의'}
      cancelText={'비동의'}
      onCancel={onClose}
      onOk={onOk}
      okButtonProps={{ disabled: !checked }}>
      <TermsTitle>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        긴급여권신청시스템(E-Pass) 개인정보동의서</TermsTitle>
      <TermsTitle>
        개인정보의 수집 및 이용에 대한 변경 동의
      </TermsTitle>
  
      <Space>
        <ExclamationCircleTwoTone twoToneColor={'#D40511'} />
        내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.
      </Space>
      <HorizontalBlank height={4} />
      <Table
        dataSource={[
          {
            index1: '필수적',
            index2:
              'email',
            index3: '본인확인, 개인 식별, 회원 가입, 긴급여권 배송신청 업무처리',
            index4: '법령상 보존의무가 없는 한 회원 탈퇴시까지',
          },
        ]}
        columns={[
          {
            title: '구분',
            width: 100,
            dataIndex: 'index1',
          },
          {
            title: '수집항목',
            dataIndex: 'index2',
          },
          {
            title: '수집∙이용목적',
            dataIndex: 'index3',
          },
          {
            title: '보유∙이용기간',
            dataIndex: 'index4',
            width: 220,
            render: (data) => <BoldFont>{data}</BoldFont>,
          },
        ]}
        pagination={false}
        bordered
      />
      <HorizontalBlank height={4} />
      <p>
        ※ 귀하는 상기 기재한 개인정보 수집 및 이용에 관하여 동의하지 않을 수 있으나, 이러한 개인정보 수집 및 이용에 동의하지 않을 경우 E-Pass 시스템에 로그인할 수 없거나 관련 업무를 수행하지 못할 수 있습니다
      </p>
      <Checkbox onChange={({ target }) => isChecked(target.checked)}>
        본인은 회사의 개인정보 수집 및 이용에 관한 설명을 이해하고, 이에 동의합니다.
      </Checkbox>
    </Modal>
  );
};

const TermsTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
`;
const BoldFont = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

export default EmailTermsModal;

import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface TooltipContentInterface {
  title: string;
  content: ReactNode;
}
const TooltipContentTemplate: FC<TooltipContentInterface> = ({ title, content }) => (
  <Container>
    <FaqHeader>
      <i>DHL</i> Korea 긴급여권 결제 지원안내
    </FaqHeader>
    <Content>{content}</Content>
  </Container>
);

const Container = styled.div`
  border-radius: inherit;
`;

const FaqHeader = styled.div`
  width: 100%;
  padding: 10px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  color: rgb(212, 5, 17);
  font-size: 17.5px;
  background-color: rgb(255, 204, 0);
  border-bottom: 2px solid rgb(212, 5, 17);
`;
const Content = styled.div`
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  background-color: white;
  color: rgb(85, 85, 85);
  padding: 10px 20px;
  width: 420px;
`;

export const RedFont = styled.span`
  color: rgb(212, 5, 17);
`;

export default TooltipContentTemplate;

import { EpadmRequestInterface, useEpadmData } from '@apis/epadm';
import CommonSpinner from '@pages/components/common/atoms/CommonSpiner';
import { Descriptions } from 'antd';
import Item from 'antd/lib/descriptions/Item';
import React, { FC } from 'react';

const ForeignMinistry: FC<EpadmRequestInterface> = ({ dhlIdx, pgIdx }) => {
  const { data, error } = useEpadmData({ dhlIdx, pgIdx });

  if (error) {
    <div>error..</div>;
  } else if (!data) {
    return <CommonSpinner />;
  }
  return (
    <Descriptions title={'외교부'} bordered column={1}>
      <Item label={'거주국가 및 공관'}>{`${data?.destName} - ${data?.embassyName}`}</Item>
      <Item label={'신청인'}>{data?.bname}</Item>
      <Item label={'민원인'}>{data?.bnameList}</Item>
      <Item label={'전화번호'}>{data?.btel}</Item>
      <Item label={'이메일'}>{data?.bemail}</Item>
    </Descriptions>
  );
};

export default ForeignMinistry;

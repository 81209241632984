import { Button, Space } from 'antd';
import { FC } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import MobilePaymentTemplates from './MobilePaymentTemplates';
import PcPaymentTemplates from './PcPaymentTemplates';

export interface IFooterProps {
  onClose: () => void;
  param?: ObjectInterface | null;
  orderId?: string;
  bnameList?: string;
}

const Footer: FC<IFooterProps> = ({ onClose, param, bnameList, orderId }) => {
  return (
    <Space>
      <BrowserView>
        <PcPaymentTemplates bnameList={bnameList} orderId={orderId} param={param} />
      </BrowserView>
      <MobileView>
        <MobilePaymentTemplates bnameList={bnameList} param={param} orderId={orderId} />
      </MobileView>
      <Button onClick={onClose}>취소</Button>
    </Space>
  );
};

export default Footer;

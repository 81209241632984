import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { FC } from 'react';
import styled from 'styled-components';
import EpassHeader from '../molecules/EpassHeader';

interface EpassLayoutInterface {
  children: React.ReactNode | React.ReactNodeArray;
}
const EpassLayout: FC<EpassLayoutInterface> = ({ children }) => {
  return (
    <Container>
      <Layout style={{ minHeight: '100vh' }}>
        <EpassHeader title='긴급여권결제 서비스(공관)'></EpassHeader>
        <Layout style={{ flexDirection: 'row' }}>
          <Content style={{ margin: 0, padding: 0 }}>{children}</Content>
        </Layout>
      </Layout>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

export default EpassLayout;

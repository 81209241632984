import { HomeRoutes, LoginRoutes, PassportRoutes } from '@constants/ConstRoute';
import EpassLayout from '@pages/components/common/organisms/EpassLayout';
import PassportLayout from '@pages/components/common/organisms/PassportLayout';
import { useAuth } from '@utils/auth';
import { Layout } from 'antd';
import { useMemo } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './App.less';
import GlobalModal from './pages/components/common/templates/GlobalModal';

const App = () => {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <Switch />
      </RecoilRoot>
    </BrowserRouter>
  );
};

const Switch = () => {
  const location = useLocation();
  const isPassport = useMemo(() => location.pathname.startsWith('/passport'), [location.pathname]);
  return isPassport ? <PassportPage /> : <EpassPage />;
};

const EpassPage = () => {
  const { isLoggedIn } = useAuth();
  return (
    <Layout>
      <EpassLayout>
        {isLoggedIn ? (
          <>
            <HomeRoutes />
          </>
        ) : (
          <>
            <LoginRoutes />
          </>
        )}
      </EpassLayout>
      <GlobalModal />
    </Layout>
  );
};

const PassportPage = () => {
  return (
    <Layout>
      <PassportLayout>
        <PassportRoutes />
      </PassportLayout>
    </Layout>
  );
};

export default App;

import { Tooltip } from 'antd';
import React, { FC, ReactNode } from 'react';
import '../../../../css/tooltip.css';

interface TooltipInterface {
  button: ReactNode;
  content: ReactNode;
}

const TooltipTemplate: FC<TooltipInterface> = ({ button, content }) => {
  return (
    <Tooltip
      placement='bottom'
      title={content}
      color={'rgb(255, 204, 0)'}
      arrowPointAtCenter={false}
      overlayStyle={{
        maxWidth: 'unset',
      }}
      overlayInnerStyle={{
        borderRadius: 5,
        padding: 0,
        // width: 500,
        marginLeft: 20,
        marginRight: 20,
      }}>
      {button}
    </Tooltip>
  );
};

export default TooltipTemplate;

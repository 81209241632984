import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import discountBanner from '@assets/images/discountBanner.png';
import kakaoChannelBanner from '@assets/images/kakaoChannelBanner.png';
import { Row, Space } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

const HeaderImage = styled.img`
  width: 50%;
  height: 50%;
  object-fit: fill;
`;

const PassportFooter: FC = () => {
  return (
    <FooterContainer>
  <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
  <HeaderImage src={kakaoChannelBanner} alt="kakaoChannelBanner" />
  <HeaderImage
    src={discountBanner}
    alt="discountBanner"
    onClick={() => window.open('https://express-resources.dhl.com/kr23-wc-promocode.html')}
    style={{ cursor: 'pointer', marginLeft: '10px' }}
  />
</div>
    <Space>
      <FollowDhlFont>Follow DHL: </FollowDhlFont>
      <Space>
        <NaverIcon onClick={() => window.open('https://blog.naver.com/dhl_story')} />
        <FacebookOutlined
          onClick={() => window.open('https://www.facebook.com/DHLExpressKorea')}
          style={{ fontSize: '2em', color: '#3b5998', cursor: 'pointer' }}
        />
        <InstagramOutlined
          onClick={() => window.open('https://instagram.com/dhl.korea')}
          style={{ fontSize: '2em', color: '#e1306c', cursor: 'pointer' }}
        />
      </Space>
    </Space>
        <div>
          <a data-document-language="true" className="link ot-sdk-show-settings">쿠키 설정</a>
          <MarginFont>{' | '}</MarginFont>
          <RedFont onClick={() => window.open('https://www.logistics.dhl/kr-ko/home/footer/local-privacy-notice.html')}>
        개인정보 처리방침
      </RedFont>
      <MarginFont>{' | '}</MarginFont>
      <RedFont onClick={() => window.open('http://www.dhl.co.kr/ko/express/shipping/shipping_advice/terms_conditions.html')}>
        운송 약관
      </RedFont>
      <MarginFont style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.logistics.dhl/kr-ko/home.html')}>
        {'©2022 DHL Express All right reserved.'}
      </MarginFont>
    </div>
  </Row>
</FooterContainer>

  );
};

const FooterContainer = styled.div`
  width: 100vw;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
`;

const FollowDhlFont = styled.div`
  font-size: 160%;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 15px;
  color: rgb(60, 72, 88);
`;

const NaverIcon = styled.a`
  :before {
    font-weight: 900;
    color: green;
    content: 'N';
    font-size: 2em;
  }
`;

const RedFont = styled.a`
  color: rgb(212, 5, 17);
  cursor: pointer;
`;

const MarginFont = styled.span``;
export default PassportFooter;

import { CheckSquareOutlined, QuestionCircleOutlined, RightSquareOutlined } from '@ant-design/icons';
import { API } from '@apis/API';
import CARD from '@assets/images/card.png';
import { EnumRouteUrl } from '@constants/ConstRoute';
import HorizontalBlank from '@pages/components/common/atoms/HorizontalBlank';
import { YellowButton } from '@pages/components/common/atoms/YellowButton';
import EmbassyCtrySelect from '@pages/home/templates/EmbassyCtrySelect';
import EmbassySelect from '@pages/home/templates/EmbassySelect';
import { formatNumber } from '@utils/numberUtil';
import { Checkbox, Col, Form, Input, InputNumber, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance } from 'src/axios/useRequest';
import styled from 'styled-components';
import AddRowTemplate from './tempates/AddRowTemplate';
import PaymentConfirmModal from './tempates/PaymentConfirmModal';
import TooltipContentTemplate, { RedFont } from './tempates/TooltipContentTemplate';
import TooltipTemplate from './tempates/TooltipTemplate';

const PaymentPage = () => {
  const { state }: any = useLocation();
  const { insurcYn,gogreenYn, isTrnsAgree, isPrivAgree1, isPrivAgree2, isPrivAgree3, isPrivAgree4, isMktAgree } = state ?? {};
  const [name, setName] = useState('');
  const [ctryCode, setCtryCode] = useState<string>();
  const [embassyCode, setEmbassyCode] = useState<string>();
  const [ctryName, setCtryName] = useState<string>();
  const [embassyName, setEmbassyName] = useState<string>();
  const [shipAmt, setShipAmt] = useState<number>(0);
  const [gogreenAmt, setGogreenAmt] = useState<number>(0);
  const [subTotalAmt, setSubTotalAmt] = useState<number>(0);
  const [checked, isChecked] = useState<boolean>(!!insurcYn);
  const [gogreenChecked, isGogreenChecked] = useState<boolean>(!!gogreenYn); // gogreenYn의 상태를 관리합니다.
  const [labelType, setLabelType] = useState<string>();

  const [paymentParameter, setPaymentParameter] = useState<ObjectInterface | null>(null);
  const [totalAmount, setTotalAmount] = useState<number>(shipAmt); // totalAmount의 상태를 관리합니다.;
  const navigate = useNavigate();

  const [form] = useForm();
  
  const fetchAmount = useCallback(
    async (embassyCode?: string) => {
      const { endpoint } = API.CUSTOMER_AMOUNT;
      const queryUrl = queryString.stringifyUrl({
        url: endpoint,
        query: { embassyCode },
      });

      
      
      const res = await axiosInstance.get(queryUrl);
      const [result] = res?.data;
      const resShipAmount = Number(result?.zoneAmount?.trim() ?? 0) ;
      const resWarFee = Number(result?.warFee?.trim() ?? 0);
      const resTradesanctionFee = Number(result?.tradesanctionFee?.trim() ?? 0);
      const resGogreenAmount = Number(result?.gogreenAmount?.trim() ?? 0);
      const resinsureFee = Number(result?.insureFee?.trim() ?? 0);

      
      setShipAmt(resShipAmount+resWarFee+resTradesanctionFee);
      setGogreenAmt(resGogreenAmount);

      // 3. 운송료(원)
      const shipAmount = `${formatNumber(resShipAmount, '원')} ${result?.zoneCode?.trim() ?? ''}(지역) + ${result?.tradesanctionFee?.trim() ?? ''}(무역제제) + ${result?.warFee?.trim() ?? ''}(분쟁지역)`;
      const gogreenAmt = `${formatNumber(resGogreenAmount, '원')}`;
      
      
      form.setFieldsValue({
        shipAmount,
        gogreenAmt,
      });
      // 3-2. 합계
      if (result?.labelType === 'WPX') {
        setLabelType(result?.labelType);
        isChecked(false);
          if (gogreenChecked) {
            form.setFieldsValue({
              totalAmount: resShipAmount + resWarFee+ resTradesanctionFee + resGogreenAmount,
              
            });
          } else { 
            form.setFieldsValue({
              totalAmount: resShipAmount + resWarFee+ resTradesanctionFee,
              
            });
          }
      } else {
        setLabelType(result?.labelType);
        isChecked(true);
          if (gogreenChecked) {
            form.setFieldsValue({
              //totalAmount: Number(result?.zoneAmount) + resGogreenAmount+resinsureFee,
              totalAmount: resShipAmount + resWarFee+ resTradesanctionFee + resGogreenAmount+resinsureFee,
            });
          } else { 
            form.setFieldsValue({
              //totalAmount: Number(result?.zoneAmount)+resinsureFee,
              totalAmount: resShipAmount + resWarFee+ resTradesanctionFee+resinsureFee,
            });
          }
      }
    },
    [setShipAmt, form],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isTrnsAgree===undefined ||isPrivAgree1===undefined||isPrivAgree2===undefined||isPrivAgree3===undefined||isPrivAgree4===undefined) {
          navigate(EnumRouteUrl.TERMS, {
          });
        }
      } catch (e: any) {
        const status = e?.response?.status
      }
    };

    fetchData();
  }, []); // 개인정보 동의 없이 바로 결제 URL들어오는것 차단

  useEffect(() => {
    embassyCode && fetchAmount(embassyCode);
  }, [embassyCode, fetchAmount]);
  return (
    <Container>
      <RedTitleFont>외교부 긴급여권 배송 서비스</RedTitleFont>
      <RedTitleFont>
        <Icon src={CARD} />
        신용카드 결제하기
      </RedTitleFont>
      <HorizontalBlank height={30} />
      <Row justify={'space-between'}>
        <Space>
          <RequiredIcon />
          <GrayDescriptionFont>는 필수입력 항목입니다.</GrayDescriptionFont>
        </Space>
        <TooltipTemplate
          content={
            <TooltipContentTemplate
              title={'DHL 긴급여권 결제 지원안내'}
              content={
                <div>
                  <RedFont>1. 카드 사용 중 프로그램 에러가 발생하셨나요?</RedFont>
                  <HorizontalBlank height={10} />
                  <p>
                    <PointIcon />
                    에러 메세지를 화면 캡쳐하여 아래 메일로 보내주시면 신속하게 처리해 드리겠습니다.
                  </p>
                  <p>
                    <PointIcon />
                    업무지원 시간 : 08:30 ~ 17:30 (GMT+9)
                  </p>
                  <p>
                    <PointIcon />
                    담당자 이메일 : retailhelp@dhl.com
                  </p>
                  <HorizontalBlank height={20} />
                  <RedFont>2. 기타 DHL여권배송이나 업무 진행에 관련된 문의사항이 있으신가요?</RedFont>
                  <HorizontalBlank height={10} />
                  <p>
                    <PointIcon />
                    아래 메일로 보내주시면 신속하게 처리해 드리겠습니다.
                  </p>
                  <p>
                    <PointIcon />
                    업무지원 시간 : 08:30 ~ 17:30 (GMT+9)
                  </p>
                  <p>
                    <PointIcon />
                    담당자 이메일 : retailhelp@dhl.com
                  </p>
                  <RedFont>3. 결제버튼 클릭후 흰색 창 팝업되며 안넘어가는 현상이 발생하셨나요?</RedFont>
                  <HorizontalBlank height={10} />
                  <p>
                    <PointIcon />
                    해외VISA, ,Master, JCB카드 모두 3D인증(보안) 이후 결제해야 정상 진행되오니 발급한 카드회사에 직접 연락하여
                    3D인증 후 다시 결제진행 부탁드립니다.
                  </p>
                </div>
              }
            />
          }
          button={
            <InfoButton>
              <QuestionIcon />
              &nbsp; 문제해결(FAQ)
            </InfoButton>
          }
        />
      </Row>
      <Form
        form={form}
        onValuesChange={(data, formData) => {
          // data.goodMoney && setGoodMoney(data.goodMoney);
          data.buyerName && setName(data.buyerName);
          if (data?.ctry) {
            form.resetFields(['embassyCode']);
          }
          if (data?.insurcYn) {
            isChecked(!!data?.insurcYn?.[0]);
          }
          if (data?.gogreenYn) {
            isGogreenChecked(!!data?.gogreenYn?.[0]);
          }
        }}
        initialValues={{
          serviceName: '외교부 긴급 여권 배송서비스',
        }}
        onFinish={(data) => {
          if (labelType === 'DOX' && checked) {
            data.insurcYn = true;
          } else {
            data.insurcYn = false;
          }
          if (gogreenChecked) { 
            data.gogreenYn = true;
          }else {
            data.gogreenYn = false;
          }
          setPaymentParameter({
            ...data,
            isMktAgree,
            ctryCode,
            embassyCode,
            ctryName,
            embassyName,
          });
        }}>
        <ItemRow>
          <Space>
            <GrayDescriptionFont>1. DHL 서비스명</GrayDescriptionFont>
            <RequiredIcon />
          </Space>
          <Form.Item name='serviceName'>
            <InputUnderline disabled />
          </Form.Item>
        </ItemRow>
        <ItemRow>
          <Space>
            <GrayDescriptionFont>2. 민원인 거주 국가 및 공관명 선택</GrayDescriptionFont>
            <RequiredIcon />
          </Space>
          <Col span={24}>
            <EmbassyCtrySelect
              isIgnoreAuth
              onChange={(value, option: any) => {
                setCtryCode(value);
                setCtryName(option.children);
              }}
            />
          </Col>
          {ctryCode && (
            <Col span={24}>
              <EmbassySelect
                ctryCode={ctryCode}
                isIgnoreAuth
                onChange={(value: string, option: any) => {
                  const stringValue = `${value}`;
                  setEmbassyCode(stringValue);
                  setEmbassyName(option?.label);
                }}
              />
            </Col>
          )}
        </ItemRow>
        <ItemRow>
          <Space>
            <GrayDescriptionFont>3. 운송료(원)</GrayDescriptionFont>
            <RequiredIcon />
            <GrayDetailFont>
              <PointIcon />
              무역제제국(콩고,이란,이라크,리비아,예멘) 50,000 운임 추가
            </GrayDetailFont>
            <GrayDetailFont>
              <PointIcon />
              분쟁지역(아프가니스탄,이라크,리비아,수단,우크라이나,예멘,이스라엘,베네수엘라) 배송 수수료 52,000 운임 추가
            </GrayDetailFont>
          </Space>
          <Form.Item
            name='shipAmount'
            rules={[
              {
                required: true,
                message:'운송료를 입력해주세요.',
              },
            ]}>
            <InputNumberUnderline
              disabled
              placeholder={'외교부 긴급 여권 배송서비스'}
              formatter={(value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              // parser={(value) => value?.replace(/\$\s?|(,*)/g, '') ?? 0}
            />
          </Form.Item>
        </ItemRow>

        <ItemRow>
          <Space>
            <GrayDescriptionFont>3-1. 서류안심 서비스 (선택사항)</GrayDescriptionFont>
            <GrayDetailFont>
              <PointIcon />
              단, 일본, 러시아, 키르기스 공화국, 이란,이집트 및 중국 내 청두 공관은 비서류로 통관되기에 서비스가 불가능 합니다.
            </GrayDetailFont>
          </Space>
          <Space>
            <Form.Item name='insurcYn' style={{ marginBottom: 0 }}>
              {labelType === 'DOX' || labelType == undefined ? (
                <Checkbox
                  checked={checked}
                  style={{ marginLeft: 20 }}
                  value={true}
                  onChange={(value) => {
                    isChecked(value.target.checked);
                    form.setFieldsValue({
                        totalAmount: value.target.checked ? shipAmt + (gogreenChecked ? gogreenAmt : 0) + 7000 : shipAmt + (gogreenChecked ? gogreenAmt : 0),
                    });
                  }}>
                  7,000 원
                </Checkbox>
              ) : (
                <Checkbox style={{ marginLeft: 20 }} value={false} disabled>
                  7,000 원
                </Checkbox>
              )}
            </Form.Item>
            <TooltipTemplate
              content={
                <TooltipContentTemplate
                  title={'DHL 긴급여권 결제 지원안내'}
                  content={
                    <div>
                      <p>
                        <PointIcon />
                        <i>DHL</i>의 책임한도를 확대한 고객안심 (Peace Of Mind) 상품으로 운송료에 7,000원을 추가하시면 긴급여권
                        운송도중 발생할 수 있는 분실 혹은 파손시 500,000원을 배상해 드리는 고객 선택 서비스입니다.
                      </p>
                    </div>
                  }
                />
              }
              button={<RedLabel>서류안심서비스</RedLabel>}
            />
          </Space>
        </ItemRow>
        
        <ItemRow>
          <Space>
            <GrayDescriptionFont>3-2. Gogreen 서비스 (선택사항)</GrayDescriptionFont>
          </Space>
          <Space>
            <Form.Item name='gogreenYn' style={{ marginBottom: 0 }}>
                <Checkbox
                  checked={gogreenChecked}
                  style={{ marginLeft: 20 }}
                  value={true}
                  onChange={(value) => {
                    isGogreenChecked(value.target.checked);
                    form.setFieldsValue({
                      totalAmount: value.target.checked ? (checked ? shipAmt +7000 + gogreenAmt: shipAmt + gogreenAmt) : (checked ? shipAmt +7000 : shipAmt),                    });
                  }}>
                  {`${formatNumber(gogreenAmt, '원')}`}
                </Checkbox>
              
            </Form.Item>
          </Space>
        </ItemRow>

        <ItemRow>
          <Space>
            <GrayDescriptionFont>3-3. 합계 (원)</GrayDescriptionFont>
          </Space>
          <Form.Item name='totalAmount'>
            <InputNumberUnderline
              disabled
              formatter={(value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              // parser={(value) => value?.replace(/\$\s?|(,*)/g, '') ?? 0}
            />
          </Form.Item>
        </ItemRow>
        <ItemRow>
          <Space>
            <GrayDescriptionFont>
              4. 민원인 정보
              <TooltipTemplate
                content={
                  <TooltipContentTemplate
                    title={'DHL 긴급여권 결제 지원안내'}
                    content={
                      <div>
                        <p>
                          <PointIcon />
                          가족신청에 한해 최대 4명까지 동봉 발송이 가능하며, 가족관계 아닐 시에는 배송지연 또는 보류될 수
                          있습니다.
                        </p>
                        <p>
                          <PointIcon />
                          6명 초과시 초과인원에 대해 새로 신청을 하여야 합니다.
                        </p>
                        <p>
                          <PointIcon />
                          최소 한 명 이상 주민등록번호(앞 6자리)를 입력하여 주시기 바랍니다. (동일한 이름의 민원인이 있을경우
                          확인용으로 사용됨)
                        </p>
                        <p>
                          <PointIcon />
                          가급적 한글로 기재해주시기 바랍니다.
                        </p>
                      </div>
                    }
                  />
                }
                button={<RedLabel style={{ marginLeft: 10 }}>유의 사항</RedLabel>}
              />
            </GrayDescriptionFont>
            <RequiredIcon />
          </Space>
          <Form.Item
            name={'buyerName'}
            label='민원인 정보'
            rules={[
              {
                required: true,
                message: '민원인정보를 입력해주세요.',
              },
            ]}>
            <InputUnderline placeholder={'신청인 이름(카드 결제자)을 한글로 기재'} />
          </Form.Item>
        </ItemRow>
        <ItemRow>
          <Space>
            <GrayDescriptionFont>4-1. 여권발급 대상자</GrayDescriptionFont>
            <GrayDetailFont>
              <PointIcon />
              여권발급 대상자는 직계가족 최대 4명까지 가능합니다.
            </GrayDetailFont>
          </Space>
          <AddRowTemplate
            onChangeSameNameCheck={(checked) => {
              checked &&
                form.setFieldsValue({
                  relevantName1: name,
                });
            }}
          />
        </ItemRow>
        <ItemRow>
          <Space>
            <GrayDescriptionFont>4-2. 전화번호</GrayDescriptionFont>
            <RequiredIcon />
          </Space>
          <Form.Item
            name={'phoneNumber'}
            rules={[
              {
                required: true,
                message: '전화번호를 입력해주세요.',
              },
              {
                validator: (_, value) => {
                  //var regPhone = /(01[0|1|6|9|7])(\d{3}|\d{4})(\d{4}$)/g;
                  let regNumber = /^[0-9]+$/;

                  if (regNumber.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('숫자만 입력하시기 바랍니다.'));
                  }
                },
              },
            ]}>
            <InputUnderline placeholder={'연락 가능한 전화번호'} />
          </Form.Item>
        </ItemRow>
        <ItemRow>
          <Space>
            <GrayDescriptionFont>4-3 이메일</GrayDescriptionFont>
            <RequiredIcon />
          </Space>
          <Form.Item
            name={'email'}
            rules={[
              {
                required: true,
                message: '이메일을 입력해주세요.',
              },
              { type: 'email', message: '이메일형식을 확인해주세요.' },
            ]}>
            <InputUnderline placeholder={'카드전표가 자동 전송 될 이메일 주소'} />
          </Form.Item>
        </ItemRow>
        <Row justify={'center'}>
          <p>
            <RedFont>결제 후 한달이내 미발송시 자동 취소(환불)됩니다.</RedFont>
          </p>
        </Row>
        <Row justify={'center'}>
          <Form.Item>
            <YellowButton htmlType={'submit'}>결제 요청</YellowButton>
          </Form.Item>
        </Row>
      </Form>

      <PaymentConfirmModal param={paymentParameter} visible={!!paymentParameter} onClose={() => setPaymentParameter(null)} />
    </Container>
  );
};

const Container = styled.div`
  padding: 15px 30px;
`;

const RedTitleFont = styled.div`
  color: #d40511;
  font-weight: 900;
  font-size: 24px;
  text-align: center;
`;

const GrayDescriptionFont = styled.div`
  color: rgb(60, 72, 88);
  font-weight: 300;
  font-size: 17px;
  text-align: center;
`;
const GrayDetailFont = styled.div`
  color: rgb(191 37 37);
  font-size: 15px;
  padding: 2px 0px 0px 20px;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;

const QuestionIcon = styled(QuestionCircleOutlined)`
  color: #d40511 !important;
  width: 8px !important;
  height: 8px !important;
`;

const RequiredIcon = styled(CheckSquareOutlined)`
  color: #d40511 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
`;

const InfoButton = styled(YellowButton)`
  font-size: 30px !important;
  width: 300px !important;
  height: 60px !important;
  border: 0 !important;
  padding: 0 !important;
`;

export const InputUnderline = styled(Input)`
  border: 0 !important;
  flex: 1 !important;
  border-bottom: 1px solid rgb(60, 72, 88) !important;
  border-radius: 0 !important;
  &:focus {
    border-bottom: 2px solid #ffcc00 !important;
    box-shadow: none !important;
  }
`;

const InputNumberUnderline = styled(InputNumber)`
  border: 0 !important;
  border-bottom: 1px solid rgb(60, 72, 88) !important;
  border-radius: 0 !important;
  width: 100% !important;
  &:focus {
    border-bottom: 2px solid #ffcc00 !important;
    box-shadow: none !important;
  }
`;

const ItemRow = styled(Row)`
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 30px !important;
`;

const RedLabel = styled.span`
  cursor: pointer;
  font-size: 9px;
  font-weight: normal;
  padding: 2px 8px;
  color: white;
  border-radius: 3px;
  background-color: #d40511;
`;

const PointIcon = styled(RightSquareOutlined)`
  margin-right: 5px !important;
`;
export default PaymentPage;

import { EpadmRequestInterface, useEpadmData } from '@apis/epadm';
import { GlobalFormat } from '@constants/Format';
import CommonSpinner from '@pages/components/common/atoms/CommonSpiner';
import HorizontalBlank from '@pages/components/common/atoms/HorizontalBlank';
import { Descriptions } from 'antd';
import Item from 'antd/lib/descriptions/Item';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

const RePaymentHistory: FC<EpadmRequestInterface> = ({ dhlIdx, pgIdx }) => {
  const { data, error } = useEpadmData({ dhlIdx, pgIdx });

  const 재결제결과 = useMemo(() => {
    const success = data?.resultcode === '00';
    if (success) {
      return (
        data?.appldate &&
        data?.appltime &&
        `재결제 성공 - ${moment(`${data?.appldate} ${data?.appltime}`).format(GlobalFormat.DateHourMinute)}}`
      );
    } else {
      return `재결제 실패 - ${data?.resultmsg}`;
    }
  }, [data?.resultcode, data?.appldate, data?.appltime, data?.resultmsg]);
  if (error) {
    <div>error..</div>;
  } else if (!data) {
    return <CommonSpinner />;
  }
  return data?.pgRYn ? (
    <>
      <HorizontalBlank height={40} />
      <Descriptions title={'재결제 내역'} bordered column={1}>
        <Item
          label={
            <Font bold color={'#433F37'}>
              재결제 결과
            </Font>
          }>
          {재결제결과}
        </Item>

        <Item label={'DHL운임'}>
          {<NumberFormat value={data?.price} displayType={'text'} thousandSeparator={true} suffix={' 원'} />}
        </Item>
        <Item label={'승인번호'}>{data?.applnum}</Item>
        <Item label={'카드종류'}>{data?.cardName}</Item>
        <Item label={'카드발급사'}>{data?.cardissuercode}</Item>
      </Descriptions>
    </>
  ) : null;
};

const Font = styled.span<{ color?: string; bold?: boolean }>`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ bold }) =>
    bold &&
    css`
      font-wight: bold;
    `}
`;

export default RePaymentHistory;

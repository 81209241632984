import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { FC } from 'react';
import styled from 'styled-components';
import PassportFooter from '../molecules/PassportFooter';
import PassportHeader from '../molecules/PassportHeader';

interface PassportLayoutInterface {
  children: React.ReactNode | React.ReactNodeArray;
}
const PassportLayout: FC<PassportLayoutInterface> = ({ children }) => {
  return (
    <Container>
      <Layout style={{ minHeight: '100vh' }}>
        <PassportHeader />
        <ContentContainer style={{ flexDirection: 'row' }}>
          <RoundedContent>{children}</RoundedContent>
        </ContentContainer>
        <div style={{ height: '80px' }} /> {/* 빈 공백을 위한 빈 div 요소 */}
        <PassportFooter />
      </Layout>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;
const RoundedContent = styled(Content)`
  border-left: 4px solid #ffcc00;
  margin: -60px 30px 0;
  background-color: white;
  z-index: 1;
  border-radius: 6px;
  height: 100%;
  box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
  padding-top: 20px;
  @media only screen and (max-width: 767px) {
    margin: 0 0 20px;
  }
`;

const ContentContainer = styled(Layout)`
  flex-direction: row;
  flex: unset;
`;
export default PassportLayout;

import { API } from '@apis/API';
import { getStorageItem, STORAGE_KEY } from '@utils/storage';
import sha256 from 'crypto-js/sha256';
import { axiosInstance } from 'src/axios/useRequest';

export interface UpdatePasswordRequestInterface {
  userId?: string;
  password: string;
  orgPassword: string;
}

export const updatePassword = async ({ password, orgPassword }: UpdatePasswordRequestInterface) => {
  const hashed = sha256(password).toString();
  const hashedOrg = sha256(orgPassword).toString();
  const userInfo: UserInfo = getStorageItem(STORAGE_KEY.USER_INFO);
  const { endpoint } = API.UPDATE_PASSWORD;
  const res = axiosInstance.post(endpoint, {
    userId: userInfo?.username,
    password: hashed,
    orgPassword: hashedOrg,
  });
  return res;
};

import { API } from '@apis/API';
import { getStorageItem, STORAGE_KEY } from '@utils/storage';
import { Form, FormItemProps, Select } from 'antd';
import { FC, useMemo } from 'react';
import { useRequest } from 'src/axios/useRequest';
import { EmbassyCtryInterface } from './SelectCountry';

interface EmbassyRequestParamInterface extends FormItemProps {
  ctryCode: string;
  embsyCode?: string;
  isIgnoreAuth?: boolean;
  onChange?: (value: string, option?: any | any[]) => void;
} 
const EmbassySelect: FC<EmbassyRequestParamInterface> = ({ ctryCode, embsyCode, isIgnoreAuth, onChange, ...props }) => {
  const userInfo: UserInfo = getStorageItem(STORAGE_KEY.USER_INFO);
  const { endpoint, method } = isIgnoreAuth ? API.CUSTOMER_SELECT : API.EMBASSY_SELECT;
  const { data, error } = useRequest<EmbassyCtryInterface[]>({
    url: endpoint,
    params: { ctryCode },
    method,
  });

  const optionList = useMemo(() => {
    const result =
      data?.map(({ embassyCode, embassyName }) => ({
        label: embassyName,
        value: embassyCode,
      })) ?? [];

    return result;
  }, [data]);

  const appRole = useMemo(() => {
    if (!userInfo) {
      return {};
    }
    const { appRole } = userInfo;
    return appRole;
  }, [userInfo]);

  if (optionList.length === 1) {
    const [item] = optionList;
    onChange && onChange(item.value, item);
  }
  if (error) {
    return <div>error..</div>;
  } else if (!data) {
    return <></>;
  }
  return (
    <Form.Item name='embassyCode' key={ctryCode} style={{ width: '100%' }} {...props}>
      <Select
        showSearch
        optionFilterProp={'children'}
        style={{ minWidth: '100%' }}
        placeholder={'공관을 선택하세요'}
        filterOption={(input, option) => {
          const label = option?.label?.toString();
          const labelLower = label?.toLowerCase();
          return (labelLower?.indexOf(input?.toLowerCase()) ?? 0) >= 0;
        }}
        onChange={(value, option) => onChange?.(value, option)}
        options={optionList}
        disabled={appRole === 'E'}></Select>
    </Form.Item>
  );
};

export default EmbassySelect;

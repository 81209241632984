import { API } from '@apis/API';
import { useRequest } from 'src/axios/useRequest';

export interface SearchPassportPaymentRequestInterface {
  reference: string;
  ctryCode?: string | null;
}

export interface SearchPassportPaymentResponseInterface {
  dhlIdx: string;
  ref: string;
  ptype: string;
  hawbIb: string;
  hawbOb: string;
  dest: string;
  destName: string;
  destStation: string;
  dhlZone: string;
  embassyCode: string;
  embassyName: string;
  bnameList: string;
  bname: string;
  btel: string;
  bemail: string;
  regDate: string;
  modDate: string;
  modUsr: string;
  payDate: string;
  pgIdx: number;
  pgCancelIdx: string;
  payCancelDate: string;
  rejectYn: YN;
  pgRIdx: string;
  payRDate: string;
  acctIb: string;
  acctOb: string;
  invNoIb: string;
  invNoOb: string;
  pgCancelRIdx: string;
  payCancelRDate: string;
  pgCancelPIdx: string;
  payCancelPDate: string;
  pgCancelP2Idx: string;
  payCancelP2Date: string;
  tid: string;
  rejectDate: string;
  hawbNew: string;
  resultmsg: string;
  appldate: string;
}

export const usePassportPayment = ({ reference, ctryCode = '' }: SearchPassportPaymentRequestInterface) => {
  const { endpoint, method } = API.PASSPORT_PAYMENT_SEARCH;
  return useRequest<SearchPassportPaymentResponseInterface>(
    {
      url: endpoint,
      params: { ref: reference, ctryCode },
      method,
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
};

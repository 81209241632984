import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ResultTemplate from './templates/ResultTemplate';
import SearchForm from './templates/SearchForm';

const SearchPage = () => {
  const location = useLocation();
  const param = new URLSearchParams(location.search);

  const [reference, setReference] = useState<string | null>(param.get('reference'));
  const [ctryCode, setCtryCode] = useState<string | null>(param.get('ctryCode'));

  return (
    <Container>
      <Title>결제내역 조회</Title>
      <p>* 결제 후 이메일로 전송 받으신 예약번호와 정확하게 일치해야 조회가 가능합니다.</p>
      <SearchForm
        reference={reference}
        ctryCode={ctryCode}
        onSearch={({ reference, ctryCode }) => {
          setReference(reference);
          setCtryCode(ctryCode || '');
        }}></SearchForm>
      {reference && <ResultTemplate {...{ reference, ctryCode }} />}
    </Container>
  );
};

const Title = styled.p`
  margin-bottom: 10px;
  font-size: 38px;
  font-weight: 700;
`;

const Container = styled.div`
  padding: 15px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;
export default SearchPage;

import BACKGROUND from '@assets/images/passportmain.jpeg';
import { Header } from 'antd/lib/layout/layout';
import styled from 'styled-components';

export const PasswordHeader = styled(Header)`
  width: 100vw !important;
  height: 60vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
  background: no-repeat 100% url(${BACKGROUND}) !important;
  background-position: center !important;
  background-size: cover !important;
  position: relative !important;
  background-attachment: fixed !important;
`;

export default PasswordHeader;

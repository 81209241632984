import Input, { InputProps, SearchProps } from 'antd/lib/input';
import React, { FC, useEffect, useState } from 'react';

interface NumericInputInterface extends InputProps, SearchProps {
  onChangeValue?: (value: string | number | readonly string[] | undefined) => void;
  isSearch?: boolean;
}

const NumericInput: FC<NumericInputInterface> = (props) => {
  const [value, setValue] = useState<string | number | readonly string[] | undefined>(props.value);
  const onChange = (e: any) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setValue(value);
    }
    props.onChange && props.onChange(e);
  };

  // '.' at the end or only '-' in the input box.
  const onBlur = (e: any) => {
    const { value } = e.target;
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    setValue(valueTemp.replace(/0*(\d+)/, '$1'));
    props.onBlur && props.onBlur(e);
  };

  useEffect(() => {
    props.onChangeValue && props.onChangeValue(value);
  }, [value, props]);
  return props?.isSearch ? (
    <Input.Search {...props} onChange={onChange} onBlur={onBlur} value={value} />
  ) : (
    <Input {...props} onChange={onChange} onBlur={onBlur} value={value} />
  );
};
export default NumericInput;

import { EnumRouteUrl } from '@constants/ConstRoute';
import sha256 from 'crypto-js/sha256';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { atom, useRecoilState } from 'recoil';
import { authApi } from 'src/axios/useRequest';
import { STORAGE_KEY, getStorageItem, removeStorageItem, setStorageItem } from './storage';

export const accessTokenAtom = atom<string>({
  key: `@auth/accessTokenAtom-${Date.now()}`,
  default: getStorageItem(STORAGE_KEY.ACCESS_TOKEN),
});

export const refreshTokenAtom = atom<string>({
  key: `@auth/refreshTokenAtom-${Date.now()}`,
  default: getStorageItem(STORAGE_KEY.REFRESH_TOKEN),
});

export const useAuth = () => {
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
  const [loading, isLoading] = useState(false);
  const navigate = useNavigate();

  const setToken = useCallback(
    (data: { accessToken: string; refreshToken: string; principal: UserInfo }) => {
      const { accessToken, refreshToken, principal } = data;
      setStorageItem(STORAGE_KEY.USER_INFO, principal);
      setRefreshToken(refreshToken);
      setAccessToken(accessToken);
    },
    [setRefreshToken, setAccessToken],
  );

  useEffect(() => {
    accessToken ? setStorageItem(STORAGE_KEY.ACCESS_TOKEN, accessToken) : removeStorageItem(STORAGE_KEY.ACCESS_TOKEN);
    refreshToken ? setStorageItem(STORAGE_KEY.REFRESH_TOKEN, refreshToken) : removeStorageItem(STORAGE_KEY.REFRESH_TOKEN);
  }, [accessToken, refreshToken]);

  const login = useCallback(
    async ({ userId, password }) => {
      isLoading(true);
      const hashed = sha256(password).toString();
      try {
        const res = await authApi.post('/comm/control/auth/signin', {
          userId,
          password: hashed,
          localApp: 'embassy',
        });
        
        const { principal } = res.data;
        const { twofactYn } = principal;
        const { v2privacyAgree } = principal;
        if (twofactYn !== 'Y') {
          setToken(res.data);
        }
        isLoading(false);
        return principal;
      } catch (e) {
        isLoading(false);
        throw e;
      }
    },
    [setToken, isLoading],
  );

  const logout = async () => {
    const userInfo = getUser();
  
    if (!userInfo) {
      console.error('No user info found');
      return;
    }
    try {
      await authApi.post('/comm/control/auth/logout', {
        userId: userInfo.username,
      });
      setRefreshToken('');
      setAccessToken('');
      removeStorageItem(STORAGE_KEY.ACCESS_TOKEN);
      removeStorageItem(STORAGE_KEY.REFRESH_TOKEN);
      removeStorageItem(STORAGE_KEY.USER_INFO);
      navigate(EnumRouteUrl.LOGOUT);
    } catch (e) {
      console.error('Error during logout: ', e);
    }
  };

  const getUser = () => getStorageItem(STORAGE_KEY.USER_INFO);

  return {
    accessToken,
    loading,
    isLoggedIn: !!accessToken,
    authAction: { login, logout, setToken, getUser },
  };
};

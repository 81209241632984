import { Modal } from 'antd';
import { FC } from 'react';
import Iframe from 'react-iframe';

interface ReceiptModalProps {
  tno?: string;
  visible: boolean;
  onClose: () => void;
}
const ReceiptModal: FC<ReceiptModalProps> = ({ tno, visible, onClose }) => {
  return (
    <Modal
      visible={visible}
      width={470}
      onCancel={onClose}
      title={'Welcome to DHL Korea'}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}>
      {tno && (
        <Iframe
          key={tno}
          styles={{ border: 0 }}
          width={'470px'}
          height={'815px'}
          url={`http://admin8.kcp.co.kr/assist/bill.BillActionNew.do?cmd=card_bill&tno=${tno}&order_no=TEST2014031013944143&trade_mony=1004`}
        />
      )}
    </Modal>
  );
};

export default ReceiptModal;

import { YellowButton } from '@pages/components/common/atoms/YellowButton';
import { Checkbox, Col, Form, message, Row, Space } from 'antd';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { InputUnderline } from '..';

interface AddRowItemInterface {
  name?: string;
  birthday?: string;
}
interface AddRowTemplateInterface {
  onChangeSameNameCheck: (checked: boolean) => void;
}

const AddRowTemplate: FC<AddRowTemplateInterface> = ({ onChangeSameNameCheck }) => {
  const [addRowList, setAddRowList] = useState<AddRowItemInterface[]>([{}]);
  const addRowHandle = () => {
    if (addRowList.length >= 4) {
      message.warning('여권발급 대상자는 최대 4명까지 등록이 가능합니다.');
      return;
    }
    setAddRowList([...addRowList, {}]);
  };
  const removeRowHandle = () => {
    addRowList.pop();
    setAddRowList([...addRowList]);
  };
  return (
    <>
      <Row justify={'space-between'}>
        <Checkbox style={{ marginLeft: 20 }} onChange={({ target }) => onChangeSameNameCheck(target.checked)}>
          신청인과 동일함
        </Checkbox>
        <Space>
          <AddButton onClick={addRowHandle}>추가</AddButton>
          <AddButton onClick={removeRowHandle}>삭제</AddButton>
        </Space>
      </Row>
      {/* underline */}
      {addRowList?.map((item, index) => {
        const rowNumber = index + 1;

        return (
          <Row gutter={30} key={`rowTmplateKey_${index}`}>
            <Col span={12}>
              <Form.Item
                name={`relevantName${rowNumber}`}
                rules={[
                  {
                    required: true,
                    message: '여권발급대상자 이름을 입력해주세요.',
                  },
                ]}>
                <InputUnderline placeholder={`${rowNumber}. 이름`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={`relevantIdNum${rowNumber}`}
                rules={[
                  {
                    required: true,
                    message: '여권발급 대상자 주민등록 번호 앞 6자리를 적어주세요.',
                  },
                  {
                    len: 6,
                    message: '6자리 숫자를 입력해주세요.',
                  },
                ]}>
                <InputUnderline placeholder={'주민번호앞6자리'} />
              </Form.Item>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

const AddButton = styled(YellowButton)`
  width: 55px !important;
  height: 25px !important;
  padding: 0 !important;
  border-radius: 3px !important;
  border: 0 !important;
`;
export default AddRowTemplate;

import { SearchPassportPaymentRequestInterface } from '@apis/searchPayment';
import { YellowButton } from '@pages/components/common/atoms/YellowButton';
import EmbassyCtrySelect from '@pages/home/templates/EmbassyCtrySelect';
import { Form, Input, Row } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
interface SearchFormInterface {
  ctryCode: string | null;
  reference: string | null;
  onSearch: (data: SearchPassportPaymentRequestInterface) => void;
}
const SearchForm: FC<SearchFormInterface> = ({ onSearch, ctryCode, reference }) => {
  return (
    <SForm
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
      initialValues={{ ctry: ctryCode, reference }}
      onFinish={(data: any) => {
        onSearch({ ...data, ctryCode: data?.ctry });
      }}>
      <Form.Item label='DHL 예약번호' name='reference'>
        <Input />
      </Form.Item>
      <Form.Item label='거주 국가'>
        <EmbassyCtrySelect isIgnoreAuth />
      </Form.Item>
      <Row justify='end'>
        <YellowButton htmlType='submit'>조회하기</YellowButton>
      </Row>
    </SForm>
  );
};

const SForm = styled(Form)`
  width: 100%;
  max-width: 800px;
`;
export default SearchForm;

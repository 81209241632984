import { AxiosResponse } from 'axios';
import { RefreshTokenAndReattempRequests } from './refreshToken';

export const responseSuccessInterceptor = async (response: AxiosResponse<any>) => {
  //console.log(response?.config?.method?.toUpperCase(), response?.config?.url, response?.status);

  return response;
};

export const responseErrorInterceptor = async (error: any) => {
  // 인증 에러 처리
  const isUnauthorized = error?.response?.status === 401 || error?.response?.status === 403;

  if (isUnauthorized) {
    return RefreshTokenAndReattempRequests(error);
  }

  console.error(
    error?.response?.config?.method?.toUpperCase(),
    error?.response?.config?.url,
    error?.response?.status,
    error?.response,
  );

  return Promise.reject(error);
};

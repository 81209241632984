import { YellowButton } from '@pages/components/common/atoms/YellowButton';
import { Row, Space } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';

interface CardInterface {
  buttonName: string;
  text1: string;
  text2: string;
  text3?: string;
  icon: React.ReactNode;
  onButtonClick: () => void;
}

export const CardTemplate: FC<CardInterface> = ({ onButtonClick, buttonName, text1, text2, text3, icon }) => {
  return (
    <RoundedContent>
      <Row justify={'center'}>
        <Title>
          {icon}
          <Text1Font>{text1}</Text1Font>
        </Title>
        <Text2Font>{text2}</Text2Font>
        <Text3Font>{text3}</Text3Font>
      </Row>

      <ButtonContainer>
        <YellowButton onClick={onButtonClick}>{buttonName}</YellowButton>
      </ButtonContainer>
    </RoundedContent>
  );
};

const RoundedContent = styled.div`
  padding: 0 20px;
  flex: 1;
  border-top: 4px solid #ffcc00;
  margin: 0 20px 40px;
  background-color: white;
  z-index: 1;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
  display: flex;
  flex-direction: column;
  min-width: 250px;
  @media only screen and (max-width: 767px) {
    margin: 0 0 20px;
  }
`;

const Title = styled(Space)``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  margin: 15px 0 25px;
  @media only screen and (max-width: 767px) {
    margin: 8px 0 10px;
  }
`;

const TextFont = styled.div`
  margin: 10px;
`;

const Text1Font = styled(TextFont)`
  font-size: 20px;
  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
  color: rgb(38, 38, 38);
`;
const Text2Font = styled(TextFont)`
  font-size: 17px;
  @media only screen and (max-width: 767px) {
    font-size: 13px;
  }
  color: rgb(60, 72, 88);
  text-align: center;
`;
const Text3Font = styled(TextFont)`
  margin-top: 20px;
  font-size: 13px;
  @media only screen and (max-width: 767px) {
    margin-top: 8px;
    font-size: 11px;
  }
  color: rgb(60, 72, 88);
  text-align: center;
`;
export default CardTemplate;

import { Button, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import queryString from 'query-string';
import { FC, useCallback, useEffect, useState } from 'react';
import { IFooterProps } from './Footer';

const MobilePaymentTemplates: FC<Partial<IFooterProps>> = ({ param, bnameList, orderId }) => {
  const [loading, isLoading] = useState(false);
  const [orderInfoForm] = useForm();
  const [payForm] = useForm();
  const handleSubmit = async () => {
    isLoading(true);

    const query = orderInfoForm.getFieldsValue([
      'site_cd',
      'ordr_idxx',
      'good_mny',
      'pay_method',
      'escw_used',
      'good_name',
      'response_type',
      'Ret_URL',
    ]);
    const url = `${process.env.REACT_APP_PAYMENT_URL}` + '/order_approval.jsp';
    const queryUrl = queryString.stringifyUrl({
      url,
      query,
    });
    const res = await axios.get(queryUrl);
    const responseData = res.data;

    if (responseData.Code === '0000') {
      orderInfoForm.setFieldsValue({
        approval_key: responseData.approvalKey,
        PayUrl: responseData.PayUrl,
        traceNo: responseData.traceNo,
      });

      call_pay_form(responseData.PayUrl);
    }
  };

  /* kcp web 결제창 호츨 (변경불가) */
  function call_pay_form(payUrl: string) {
    const v_frm: any = document.getElementById('order_info');
    // 인코딩 방식에 따른 변경 -- Start
    if (v_frm.encoding_trans == undefined) {
      v_frm.action = payUrl;
    } else {
      if (v_frm.encoding_trans.value == 'UTF-8') {
        v_frm.action = payUrl.substring(0, payUrl.lastIndexOf('/')) + '/jsp/encodingFilter/encodingFilter.jsp';
        v_frm.PayUrl.value = payUrl;
      } else {
        v_frm.action = payUrl;
      }
    }
    // 인코딩 방식에 따른 변경 -- End

    if (v_frm.Ret_URL.value === '') {
      /* Ret_URL값은 현 페이지의 URL 입니다. */
      alert('연동시 Ret_URL을 반드시 설정하셔야 됩니다.');
      return false;
    } else {
      v_frm.submit();
    }
  }

  const init_orderid = useCallback(() => {
    const today = new Date();
    const year = today.getFullYear();
    let month: any = today.getMonth() + 1;
    let date: any = today.getDate();

    if (month < 10) month = '0' + month;

    if (date < 10) date = '0' + date;

    const ipgm_date = year + '' + month + '' + date;

    orderInfoForm.setFieldsValue({
      ipgm_date,
    });
  }, [orderInfoForm]);

  useEffect(() => {
    init_orderid();
  }, [init_orderid]);

  useEffect(() => {
    payForm.setFieldsValue({});
  }, [payForm, orderId, bnameList, param]);

  useEffect(() => {
    const insertParam = {
      bnameList,
      cityListSelect: param?.ctry,
      embassyListSelect: param?.embassyCode,
      isMktAgree: param?.isMktAgree ? 'Y' : 'N',
      insurcYn: param?.insurcYn,
      gogreenYn: param?.gogreenYn,
    };
    orderInfoForm.setFieldsValue({
      param_opt_1: JSON.stringify(insertParam),
      bname: param?.buyerName,
      use_pay_method: '100000000000',
      pay_method: 'CARD',
      btel: param?.phoneNumber,
      good_name: param?.serviceName,
      ActionResult: 'card',
      ordr_idxx: orderId,
      good_mny: param?.totalAmount ?? 0,
      buyr_name: param?.buyerName,
      buyr_mail: param?.email,
      buyr_tel1: '',
      buyr_tel2: param?.phoneNumber,
      req_tx: 'pay',
      shop_name: 'DHL EXPRESS KOREA',
      site_cd: process.env.REACT_APP_PAY_SITE_CD,
      currency: '410',
      escw_used: 'N',
      quotaopt: '0',
      Ret_URL: process.env.REACT_APP_PAYMENT_URL + '/order_mobile_react.jsp',
      tablet_size: '1.0',
      response_type: 'JSON',
      encoding_trans: 'UTF-8',
      disp_tax_yn: 'N',
      coupon_apply_yn: 'N',
      card_point: 'N',
    });
  }, [param, orderInfoForm, orderId, bnameList]);

  return (
    <>
      <Button loading={loading} onClick={handleSubmit}>
        결제하기
      </Button>
      <Form acceptCharset='UTF-8' form={orderInfoForm} id='order_info' name='order_info' method='post'>
        <Form.Item name={'cityListSelect'} hidden>
          <input type='hidden' name='cityListSelect' />
        </Form.Item>
        <Form.Item name={'embassyListSelect'} hidden>
          <input type='hidden' name='embassyListSelect' />
        </Form.Item>
        <Form.Item name={'serviceName'} hidden>
          <input type='hidden' name='serviceName' />
        </Form.Item>
        <Form.Item name={'totalAmount'} hidden>
          <input type='hidden' name='totalAmount' />
        </Form.Item>
        <Form.Item name={'bname'} hidden>
          <input type='hidden' name='bname' />
        </Form.Item>
        <Form.Item name={'bnameList'} hidden>
          <input type='hidden' name='bnameList' />
        </Form.Item>
        <Form.Item name={'btel'} hidden>
          <input type='hidden' name='btel' />
        </Form.Item>
        <Form.Item name={'isMktAgree'} hidden>
          <input type='hidden' name='isMktAgree' />
        </Form.Item>
        <Form.Item name={'insurcYn'} hidden>
          <input type='hidden' name='insurcYn' />
        </Form.Item>
        <Form.Item name={'gogreenYn'} hidden>
          <input type='hidden' name='gogreenYn' />
        </Form.Item>
        <Form.Item name={'orderid'} hidden>
          <input type='hidden' name='orderid' />
        </Form.Item>
        <Form.Item hidden name={'ActionResult'}>
          <input type='hidden' name='ActionResult' />
        </Form.Item>
        <Form.Item hidden name={'ordr_idxx'}>
          <input type='hidden' name='ordr_idxx' />
        </Form.Item>
        <Form.Item hidden name={'good_name'}>
          <input type='hidden' name='good_name' />
        </Form.Item>
        <Form.Item hidden name={'good_mny'}>
          <input type='hidden' name='good_mny' />
        </Form.Item>
        <Form.Item hidden name={'buyr_name'}>
          <input type='hidden' name='buyr_name' />
        </Form.Item>
        <Form.Item hidden name={'buyr_mail'}>
          <input type='hidden' name='buyr_mail' />
        </Form.Item>
        <Form.Item hidden name={'buyr_tel1'}>
          <input type='hidden' name='buyr_tel1' />
        </Form.Item>
        <Form.Item hidden name={'buyr_tel2'}>
          <input type='hidden' name='buyr_tel2' />
        </Form.Item>
        <Form.Item hidden name={'req_tx'}>
          <input type='hidden' name='req_tx' />
        </Form.Item>
        <Form.Item hidden name={'shop_name'}>
          <input type='hidden' name='shop_name' />
        </Form.Item>
        <Form.Item hidden name={'site_cd'}>
          <input type='hidden' name='site_cd' />
        </Form.Item>
        <Form.Item hidden name={'site_logo'}>
          <input type='hidden' name='site_logo' value={process.env.REACT_APP_PAYMENT_URL + '/DHL_logo.gif'} />
        </Form.Item>
        <Form.Item hidden name={'currency'}>
          <input type='hidden' name='currency' />
        </Form.Item>
        <Form.Item hidden name={'approval_key'}>
          <input type='hidden' name='approval_key' />
        </Form.Item>
        <Form.Item hidden name={'escw_used'}>
          <input type='hidden' name='escw_used' />
        </Form.Item>
        <Form.Item hidden name={'pay_method'}>
          <input type='hidden' name='pay_method' />
        </Form.Item>
        <Form.Item hidden name={'van_code'}>
          <input type='hidden' name='van_code' />
        </Form.Item>
        <Form.Item hidden name={'quotaopt'}>
          <input type='hidden' name='quotaopt' />
        </Form.Item>
        <Form.Item hidden name={'ipgm_date'}>
          <input type='hidden' name='ipgm_date' />
        </Form.Item>
        <Form.Item hidden name={'Ret_URL'}>
          <input type='hidden' name='Ret_URL' />
        </Form.Item>
        <Form.Item hidden name={'tablet_size'}>
          <input type='hidden' name='tablet_size' />
        </Form.Item>
        <Form.Item hidden name={'param_opt_1'}>
          <input type='hidden' name='param_opt_1' />
        </Form.Item>
        <Form.Item hidden name={'param_opt_2'}>
          <input type='hidden' name='param_opt_2' />
        </Form.Item>
        <Form.Item hidden name={'param_opt_3'}>
          <input type='hidden' name='param_opt_3' />
        </Form.Item>
        <Form.Item hidden name={'response_type'}>
          <input type='hidden' name='response_type' />
        </Form.Item>
        <Form.Item hidden name={'PayUrl'}>
          <input type='hidden' name='PayUrl' id='PayUrl' />
        </Form.Item>
        <Form.Item hidden name={'traceNo'}>
          <input type='hidden' name='traceNo' id='traceNo' />
        </Form.Item>
        <Form.Item hidden name={'encoding_trans'}>
          <input type='hidden' name='encoding_trans' />
        </Form.Item>
        <Form.Item hidden name={'used_card'}>
          <input type='hidden' name='used_card' />
        </Form.Item>
        <Form.Item hidden name={'kcp_noint'}>
          <input type='hidden' name='kcp_noint' />
        </Form.Item>
        <Form.Item hidden name={'kcp_noint_quota'}>
          <input type='hidden' name='kcp_noint_quota' />
        </Form.Item>
        <Form.Item hidden name={'tax_flag'}>
          <input type='hidden' name='tax_flag' />
        </Form.Item>
        <Form.Item hidden name={'comm_tax_mny'}>
          <input type='hidden' name='comm_tax_mny' />
        </Form.Item>
        <Form.Item hidden name={'comm_vat_mny'}>
          <input type='hidden' name='comm_vat_mny' />
        </Form.Item>
        <Form.Item hidden name={'comm_free_mny'}>
          <input type='hidden' name='comm_free_mny' />
        </Form.Item>
        <Form.Item hidden name={'eng_flag'}>
          <input type='hidden' name='eng_flag' />
        </Form.Item>
        <Form.Item hidden name={'shop_user_id'}>
          <input type='hidden' name='shop_user_id' />
        </Form.Item>
        <Form.Item hidden name={'pt_memcorp_cd'}>
          <input type='hidden' name='pt_memcorp_cd' />
        </Form.Item>
        <Form.Item hidden name={'disp_tax_yn'}>
          <input type='hidden' name='disp_tax_yn' />
        </Form.Item>
        <Form.Item hidden name={'coupon_apply_yn'}>
          <input type='hidden' name='coupon_apply_yn' />
        </Form.Item>
        <Form.Item hidden name={'card_point'}>
          <input type='hidden' name='card_point' />
        </Form.Item>
        <Form.Item hidden name={'use_pay_method'}>
          <input type='hidden' name='use_pay_method' />
        </Form.Item>
      </Form>
    </>
  );
};

export default MobilePaymentTemplates;

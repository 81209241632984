import { InfoCircleOutlined } from '@ant-design/icons';
import { EnumRouteUrl } from '@constants/ConstRoute';
import HorizontalBlank from '@pages/components/common/atoms/HorizontalBlank';
import { Button, Row, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface DocumentSafeInfoModalInterface {
  visible: boolean;
  onClose: () => void;
  checkedList: string[];
  confirmValue: boolean;
}

const DocumentSafeInfoModal: FC<DocumentSafeInfoModalInterface> = ({ visible, onClose, checkedList,confirmValue }) => {
  const navigate = useNavigate();
  const isTransferAgree = useMemo(() => checkedList.find((item) => item === '운송약관'), [checkedList]);
  const isPrivacyAgree1 = useMemo(() => checkedList.find((item) => item === '개인정보1'), [checkedList]);
  const isPrivacyAgree2 = useMemo(() => checkedList.find((item) => item === '개인정보2'), [checkedList]);
  const isPrivacyAgree3 = useMemo(() => checkedList.find((item) => item === '개인정보3'), [checkedList]);
  const isPrivacyAgree4 = useMemo(() => checkedList.find((item) => item === '개인정보4'), [checkedList]);
  const isMarketingAgree1 = useMemo(() => checkedList.includes('마케팅1'), [checkedList]);
  const isMarketingAgree2 = useMemo(() => checkedList.includes('마케팅2'), [checkedList]);
  const okHandle = (answer: boolean) => {
    const param = {
      insurcYn: answer,
      isTrnsAgree: isTransferAgree,
      isPrivAgree1: isPrivacyAgree1,
      isPrivAgree2: isPrivacyAgree2,
      isPrivAgree3: isPrivacyAgree3,
      isPrivAgree4: isPrivacyAgree4,
      isMktAgree1: isMarketingAgree1,
      isMktAgree2: isMarketingAgree2,
      isMktAgree: isMarketingAgree1 && isMarketingAgree2,
      gogreenYn: confirmValue,
    };
    navigate(EnumRouteUrl.PAYMENT, {
      state: param,
    });
  };
  return (
    <Modal title='서류안심 서비스 안내' visible={visible} onCancel={onClose} footer={null}>
      <p>
        DHL 외교부 긴급 여권 신청시 <RedFont>서류 안심서비스</RedFont>를 이용하시겠습니까?
      </p>
      <p>
        <RedFont>서류 안심서비스</RedFont>를 통해 서류분실위험에 대한 보호를 제공받을수 있습니다.
      </p>
      <div>
        <Space>
          <RedInfo />
          <span>서비스수수료 : 7천원</span>
        </Space>
      </div>
      <div>
        <Space>
          <RedInfo />
          <span>분실및 파손시 70만원 보상</span>
        </Space>
      </div>
      <HorizontalBlank height={20} />
      <Row justify={'end'}>
        <Space>
          <Button onClick={() => okHandle(true)}>예</Button>
          <Button onClick={() => okHandle(false)}>아니오</Button>
        </Space>
      </Row>
    </Modal>
  );
};
const RedFont = styled.span`
  color: red;
`;
const RedInfo = styled(InfoCircleOutlined)`
  color: red;
`;

export default DocumentSafeInfoModal;

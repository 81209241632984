import { API } from '@apis/API';
import { getStorageItem, STORAGE_KEY } from '@utils/storage';
import { Form, FormItemProps, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { FC, useMemo } from 'react';
import { useRequest } from 'src/axios/useRequest';
import { EmbassyCtryInterface } from './SelectCountry';

interface EmbassyCtrySelectProps extends FormItemProps {
  onChange?: ((value: string, option: DefaultOptionType) => void) | undefined;
  isIgnoreAuth?: boolean;
}
const EmbassyCtrySelect: FC<EmbassyCtrySelectProps> = ({ onChange, isIgnoreAuth, ...props }) => {
  
  const userInfo: UserInfo = getStorageItem(STORAGE_KEY.USER_INFO);
  const { Option, OptGroup } = Select;
  const { endpoint, method } = isIgnoreAuth ? API.CUSTOMER_CTRY_SELECT : API.EMBASSY_CTRY_SELECT;
  const { data, error } = useRequest<EmbassyCtryInterface[]>({
    url: endpoint,
    method,
    params: { embassyCode: userInfo ? userInfo.embassyCode || '' : '' },
  });

  const [favoriteList, normalList] = useMemo(() => {
    const favoriteList = data?.filter((item) => item.favoriteYn === 'Y');
    const normalList = data?.filter((item) => item.favoriteYn !== 'Y');
    
    return [favoriteList, normalList];
  }, [data]);

  const appRole = useMemo(() => {
    if (!userInfo) {
      return {};
    }
    const { appRole } = userInfo;
    return appRole;
  }, [userInfo]);

  if (error) {
    return <div>error..</div>;
  } else if (!data) {
    return <></>;
  }
  return (
    <Form.Item name={'ctry'} style={{ width: '100%' }} {...props}>
      <Select
        showSearch
        optionFilterProp={'label'}
        filterOption={(input, option) => {
          const label = option?.children?.toString();
          const labelLower = label?.toLowerCase();
          return (labelLower?.indexOf(input?.toLowerCase()) ?? -1) >= 0;
        }}
        filterSort={(optionA, optionB) => {
          const optionALabel = optionA?.children?.toString()?.toLowerCase();
          const optionBLabel = optionB?.children?.toString()?.toLowerCase();
          return optionALabel && optionBLabel ? optionALabel?.localeCompare(optionBLabel) : 0;
        }}
        onChange={(value: string, option: any) => onChange && onChange(value, option)}
        style={{ minWidth: '100%' }}
        placeholder={'국가를 선택하세요'}
        // disabled={appRole === 'E'}
      >
        <Option value={''} key={'all'} disabled={appRole === 'E'}>
          {'전체'}
        </Option>
        <OptGroup label={'favorite'}>
          {favoriteList?.map((item) => (
            <Option value={item.ctryCode} key={item.ctryCode}>
              {item.ctryName}
            </Option>
          ))}
        </OptGroup>
        {normalList?.map((item) => (
          <Option value={item.ctryCode} key={item.ctryCode}>
            {item.ctryName}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default EmbassyCtrySelect;

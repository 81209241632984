import { CarFilled, SearchOutlined } from '@ant-design/icons';
import { SearchPassportPaymentRequestInterface, usePassportPayment } from '@apis/searchPayment';
import { EnumRouteUrl } from '@constants/ConstRoute';
import HorizontalBlank from '@pages/components/common/atoms/HorizontalBlank';
import { YellowButton } from '@pages/components/common/atoms/YellowButton';
import { Button, Descriptions, Empty, Space } from 'antd';
import Item from 'antd/lib/descriptions/Item';
import useModal from 'antd/lib/modal/useModal';
import { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

const ResultTemplate: FC<SearchPassportPaymentRequestInterface> = ({ reference, ctryCode }) => {
  const { data, error } = usePassportPayment({ reference, ctryCode });
  const [modal, contextHolder] = useModal();
  const navigate = useNavigate();

  const printAreaRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printAreaRef.current,
  });

  if (error) {
    return <div>error..</div>;
  } else if (!data) {
    return <Empty style={{ marginBottom: 50 }} />;
  }

  return (
    <ResultContainer>
      <div ref={printAreaRef}>
        <Descriptions title={'결제내역'} bordered column={1} size={'small'}>
          <Item label={'결제수단'}>신용카드</Item>
          <Item label={'DHL 예약번호'}>{data?.ref}</Item>
          <Item label={'운송장번호'}>
            {data?.hawbOb && (
              <a
                onClick={() =>
                  window.open(`http://www.dhl.com/cgi-bin/tracking.pl?AWB=${data?.hawbOb}&LINKS=NO&LAN=KOR&TID=KR_KOR`)
                }>
                <span>{data?.hawbOb}</span>
                <SearchOutlined style={{ marginLeft: 10 }} />
              </a>
            )}
          </Item>
          <Item label={'지역'}>{`${data?.dhlZone}지역`}</Item>
          <Item label={'국가'}>{data?.destName}</Item>
          <Item label={'공관'}>{data?.embassyName}</Item>
          <Item label={'여권발급대상자'}>{data?.bnameList}</Item>
          <Item label={'처리결과'}>{data?.resultmsg}</Item>
          <Item label={'처리시각'}>{data?.appldate}</Item>
        </Descriptions>
        <div style={{ paddingTop: 10 }}>
          <Button onClick={handlePrint} style={{ width: 100, float: 'right', color: 'red' }}>
            Print
          </Button>
        </div>
      </div>
      <HorizontalBlank height={30} />
      <IconRedFont>취소는 DHL Retail팀 (retailhelp@dhl.com)으로 요청을 부탁드립니다.</IconRedFont>
      <IconRedFont>AWB이 생성되지 않은 경우(현재 미발송)는 조회 불가, 발송일 기준 90일 이내만 조회가능.</IconRedFont>
      <div>
        <YellowButton2
          onClick={() => {
            navigate(EnumRouteUrl.PASSPORT_MAIN);
          }}
          htmlType='submit'>
          홈으로 이동
        </YellowButton2>
      </div>
    </ResultContainer>
  );
};

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
`;

const IconRedFont: FC = ({ children }) => (
  <RedFontContainer>
    <CarFilled />
    <RedFont>{children}</RedFont>
  </RedFontContainer>
);

const RedFontContainer = styled(Space)`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;

const YellowButton2 = styled(YellowButton)`
  float: right;
  margin-top: -60px;
  @media only screen and (max-width: 767px) {
    margin-top: 0px;
  }
`;

const RedFont = styled.span`
  color: #d40511;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
`;
// 결제수단 -> 신용카드
// DHL 예약번호 -> ref
// 운송장번호 -> hawbOb
// 지역 -> dhl_zone + '지역'
// 국가 -> destName
// 공관 -> embassyName
// 민원인 -> bname
// 처리결과 ->resultmsg
// 처리시각 ->appldate

export default ResultTemplate;
